import React from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '../../../../../components/CustomSelect';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  filteredDSEList: PropTypes.instanceOf(Array).isRequired,
  menu: PropTypes.instanceOf(Object).isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.instanceOf(Object).isRequired,
  enableErrorDisplay: PropTypes.bool.isRequired,
  index: PropTypes.number,
  className: PropTypes.string.isRequired,
};

const ROUTE_VISIT_TYPE_LIST = [
  {
    id: 'ONE_DAY',
    title: 'ONE DAY',
  },
  {
    id: 'WEEK_LONG',
    title: 'WEEK LONG',
  },
  {
    id: 'MONTH_LONG',
    title: 'MONTH LONG',
  },
];

const ROUTE_DELIVERY_TYPE_LIST = [
  {
    id: 'PRE_ORDER',
    title: 'PRE ORDER',
  },
  {
    id: 'READY_STOCK',
    title: 'READY STOCK',
  },
];

const ROUTE_VISIT_CATEGORY_LIST = [
  {
    id: 'OPEN',
    title: 'OPEN',
  },
  {
    id: 'SCHEDULED',
    title: 'SCHEDULED',
  },
];

const ROUTE_VISIT_FREQUENCY_LIST = [
  {
    id: 'DAILY',
    title: 'DAILY',
  },
  {
    id: 'WEEKLY',
    title: 'WEEKLY',
  },
  {
    id: 'FORTNIGHTLY',
    title: 'FORTNIGHTLY',
  },
  {
    id: 'MONTHLY',
    title: 'MONTHLY',
  },
];

const RouteDetailInfo = ({
  data,
  filteredDSEList,
  onDropDownChange,
  refsObj,
  enableErrorDisplay,
  index = 0,
  className,
}) => {
  const distributorList = data.assignedLines[index].distributorList || [];
  return (
    <div className={`form-input-wrap ${className}`}>
      <CustomSelect
        enableValidation
        labelContent='Assign User'
        className='custom-select'
        options={filteredDSEList.filter((arr) => !data.assignedLines.map((d) => d.assignedTo).includes(arr.id))}
        placeholder='Select User'
        getOptionValue={({ id }) => id}
        getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
        onChange={(event) => {
          onDropDownChange(event.id, ['assignedLines', index, 'assignedTo'], data.assignedLines[index].id, event);
        }}
        value={filteredDSEList.filter(({ id }) => id === data.assignedLines[index].assignedTo)}
        ref={(ref) => (refsObj.assignedTo = ref)}
        enableErrorDisplay={enableErrorDisplay}
      />

      {distributorList.length > 1 && (
        <CustomSelect
          enableValidation
          labelContent='Distributor'
          className='custom-select'
          options={distributorList}
          placeholder='Select Distributor'
          getOptionValue={({ id }) => id}
          getOptionLabel={({ title }) => title}
          onChange={(event) => {
            onDropDownChange(event.id, ['assignedLines', index, 'distributorId'], data.assignedLines[index].id);
          }}
          value={distributorList.filter(({ id }) => id === data.assignedLines[index].distributorId)}
          ref={(ref) => (refsObj.assignedTo = ref)}
          enableErrorDisplay={enableErrorDisplay}
        />
      )}

      <CustomSelect
        enableValidation
        labelContent='Visit Frequency'
        className='custom-select'
        options={ROUTE_VISIT_FREQUENCY_LIST}
        placeholder='Select Route Visit Frequency'
        getOptionValue={({ id }) => id}
        getOptionLabel={({ title }) => title}
        enableErrorDisplay={enableErrorDisplay}
        onChange={(event) =>
          onDropDownChange(event.id, ['assignedLines', index, 'visitFrequency'], data.assignedLines[index].id)
        }
        value={ROUTE_VISIT_FREQUENCY_LIST.filter(({ title }) => title === data.assignedLines[index].visitFrequency)}
        ref={(ref) => (refsObj.visitFrequency = ref)}
      />

      {/* <CustomSelect */}
      {/*  enableValidation */}
      {/*  labelContent="Visit Category" */}
      {/*  className="custom-select" */}
      {/*  options={ROUTE_VISIT_CATEGORY_LIST} */}
      {/*  placeholder="Select Route Visit Category" */}
      {/*  getOptionValue={({ id }) => id} */}
      {/*  getOptionLabel={({ title }) => title} */}
      {/*  enableErrorDisplay={enableErrorDisplay} */}
      {/*  onChange={event => onDropDownChange(event.id, ['assignedLines', index, 'visitCategory'])} */}
      {/*  value={ROUTE_VISIT_CATEGORY_LIST.filter(({ title }) => title === data.assignedLines[index].visitCategory)} */}
      {/*  ref={ref => refsObj.visitCategory = ref} */}
      {/* /> */}

      <CustomSelect
        enableValidation
        labelContent='Visit Type'
        className='custom-select'
        options={ROUTE_VISIT_TYPE_LIST}
        placeholder='Select Route Visit Type'
        getOptionValue={({ id }) => id}
        getOptionLabel={({ title }) => title}
        enableErrorDisplay={enableErrorDisplay}
        onChange={(event) =>
          onDropDownChange(event.id, ['assignedLines', index, 'visitType'], data.assignedLines[index].id)
        }
        value={ROUTE_VISIT_TYPE_LIST.filter(({ id }) => id === data.assignedLines[index].visitType)}
        ref={(ref) => (refsObj.visitType = ref)}
      />

      <CustomSelect
        enableValidation
        labelContent='Delivery Type'
        className='custom-select'
        options={ROUTE_DELIVERY_TYPE_LIST}
        placeholder='Select Route Delivery Type'
        getOptionValue={({ id }) => id}
        getOptionLabel={({ title }) => title}
        enableErrorDisplay={enableErrorDisplay}
        onChange={(event) =>
          onDropDownChange(event.id, ['assignedLines', index, 'deliveryType'], data.assignedLines[index].id)
        }
        value={ROUTE_DELIVERY_TYPE_LIST.filter(({ id }) => id === data.assignedLines[index].deliveryType)}
        ref={(ref) => (refsObj.deliveryType = ref)}
      />
    </div>
  );
};

RouteDetailInfo.propTypes = propTypes;

RouteDetailInfo.defaulProps = {
  index: 0,
};

export default RouteDetailInfo;
