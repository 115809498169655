import styled from 'styled-components';

export const DetailStyled = styled.div`
  .field-section {
    padding: 32px 48px 8px 48px;
    display: flex;
    background: #ffffff;
    border-bottom: 1px solid #e7e7ed;

    .targetBtn {
      border-radius: 4px;
      border: 1px solid var(--primary-primary, #0b5fff);
      background: var(--light-white, #fff);
      color: #0b5fff;
      margin-left: 24px;
    }
    .permission-choose {
      margin-top: 10px;
      margin-left: 24px;
      width: 25%;

      > label {
        display: block;
        margin-bottom: 16px;
        color: #272833;
        font-size: 14px;
        font-weight: 600;
      }

      > div {
        display: flex;
      }

      .radio-list {
        margin-right: 24px;
        cursor: pointer;
        display: flex;
        justify-content: center;

        &:last-child {
          margin-right: 0;
        }
      }

      .text {
        font-size: 12px;
        font-weight: 600;
        color: #272833;
        display: inline-block;
      }
    }

    .month-wrap {
      width: 25%;
      padding-right: 24px;
      label {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 4px;

        + div {
          width: 100%;
          background: #f1f2f5;
          border: 1px solid #e7e7ed;
          border-radius: 4px;
          padding: 0 16px;

          > div {
            margin: 0;
            display: block;
          }
        }
      }
      .month-view {
        position: relative;

        .month-icon {
          position: absolute;
          right: 0;
          margin: 0;
        }

        .ic-dropdown {
          display: none;
        }
      }
    }
    .form-input {
      width: 25%;
    }
  }

  .new-view {
    .field-section {
      display: block;
      border-bottom: 0;
      .list-item {
        margin-bottom: 32px;
        label {
          font-size: 14px;
          color: #272833;
          line-height: 21px;
          font-weight: 600;
          margin-bottom: 12px;
        }
        p {
          margin: 0;
          line-height: 21px;
          font-weight: 400;
        }
      }
      .month-wrap {
        padding-right: 0;
      }
      .form-select-input {
        .select-css .zindex-2__control {
          background-color: #f1f2f5;
          border-color: #e7e7ed;
          margin-top: 1px;
        }
      }
    }
    .mt-24 {
      margin: 0;
      text-align: right;
      padding: 24px 0 32px 0;
      border-top: 1px solid #e7e7ed;
    }
    .form-select-input,
    .month-wrap,
    .form-input {
      width: unset;
      display: block;
      margin-bottom: 24px;
    }
    .permission-choose {
      width: unset;
      display: block;
      margin: 0;
      margin-bottom: 32px;
    }
    .table-wrap {
      padding: 0 48px 32px 48px;
      background-color: #fff;
      border-top: 1px solid #e7e7ed;
      padding-top: 24px;
      table {
        thead tr {
          height: 40px;
        }
        tr {
          cursor: unset;
        }
        .w-200 .form-input {
          margin: 0;
          input {
            text-align: right;
            height: 32px;
          }
        }
      }
    }
    .w-200 {
      width: 200px;
    }
  }
`;
