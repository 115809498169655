import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT, GET_DISTRIBUTORS } from '../../common/GqlAPI';

const GET_WAREHOUSE_INVENTORIES = gql`
  query WarehouseInventories($filter: FilterInput!) {
    warehouseInventories(filter: $filter) {
      rows {
        distributor_id
        distributor
        bin_location_id
        bin_code
        sellable
        expired
        damaged
        count
      }
      count
    }
  }
`;
const GET_WAREHOUSE_DETAILS_INVENTORY = gql`
  query GetBinLocationStockDetail($distributorId: Int!, $binLocationId: Int!, $filter: FilterInput!) {
    getBinLocationStockDetail(distributorId: $distributorId, binLocationId: $binLocationId, filter: $filter) {
      distributor_id
      bin_location_id
      sku_batch_id
      batch_name
      sku_id
      sku
      manufacture_date
      expiry_date
      closing_sellable
      closing_damages
      closing_expiry
    }
  }
`;

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getWarehouseInventories: GET_WAREHOUSE_INVENTORIES,
  getWarehouseDetailsInventory: GET_WAREHOUSE_DETAILS_INVENTORY,
};
const TRANSFER_SKU_BATCH = graphql(
  gql`
    mutation TransferSKUBatchToNewBinLocation(
      $skuBatchId: Int!
      $fromBinLocationId: Int!
      $transferQuantity: TransferQuantity!
    ) {
      transferSKUBatchToNewBinLocation(
        skuBatchId: $skuBatchId
        fromBinLocationId: $fromBinLocationId
        transferQuantity: $transferQuantity
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      TransferSKUBatchToNewBinLocation: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
  TransferSKUBatchToNewBinLocation: TRANSFER_SKU_BATCH,
};

export { query, mutation, DOWNLOAD_REPORT, TRANSFER_SKU_BATCH };
