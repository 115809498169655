import { totalPriceMapper } from '../create/config';
import { colSize } from '../../../../data/enums/Styles';
import { SRN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { imageSliderListFormatter } from '../../../common/HelperFunctions';
import { PAYMENT_MODE } from '../../orderProcessing/received/salesInvoice/customerDetail/config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Returns',
    path: `/${SRN}`,
    active: true,
  },
];

const summaryDetailsMapper = data => {
  const summaryList = [
    {
      title: 'SRN Invoice Number',
      value: data.SRNInvoiceNumber || '',
      colSpace: 3,
    },
    {
      title: 'Sales Invoice Number',
      value: data.invoiceNumber || '',
      colSpace: 3,
    },
    {
      title: 'Outlet',
      value: data.RetailOutlet ? data.RetailOutlet.title : '',
      colSpace: 3,
    },
    {
      title: '',
      value: '',
      colSpace: 3,
    },
    ...(data?.Distributor?.servicesUsed?.billing?.status
      ? [
          {
            title: 'Payment Mode',
            value:
              data?.paymentMode === PAYMENT_MODE.CREDIT_ROSIA ? PAYMENT_MODE?.CREDIT_BILLING : data?.paymentMode || '',
            colSpace: colSize.tri,
          },
        ]
      : []),
    {
      title: 'Ledger',
      value: data.Customer ? data.Customer.name || '' : '',
      colSpace: colSize.tri,
    },
    {
      title: 'Bill Name',
      value: data.customerName ? data.customerName : '',
      colSpace: colSize.tri,
    },
    {
      title: 'Address',
      value: data.Customer ? data.Customer.address || '' : '',
      colSpace: colSize.tri,
    },
    {
      title: 'PAN',
      value: data.Customer ? data.Customer.panNumber || '' : '',
      colSpace: colSize.tri,
    },
    {
      title: 'Remarks',
      value: data.reason || '',
      colSpace: 4,
    },
    {
      title: 'SRN Date',
      value: data.srnDate ? data.srnDate : '',
      colSpace: 4,
    },
  ];
  return summaryList;
};

const lineMapper = lines =>
  lines.map(item => {
    /* item.images = [{
    thumbnail: 'https://storage.googleapis.com/rmap_images/149/149_Yuna Cold Store_1_1461062344.jpeg',
    w: 1200,
    h: 900,
    title: '2016-04-19',
    src: 'https://storage.googleapis.com/rmap_images/149/149_Yuna Cold Store_1_1461062344.jpeg',
  },
  {
    src: 'https://storage.googleapis.com/rmap_images/149/149_Yuna Cold Store_1_1463991987.jpeg',
    thumbnail: 'https://storage.googleapis.com/rmap_images/149/149_Yuna Cold Store_1_1463991987.jpeg',
    w: 1200,
    h: 900,
    title: '2016-05-23',
  },
  {
    src: 'https://storage.googleapis.com/rmap_images/149/149_Yuna Cold Store_1_1473078477.jpeg',
    thumbnail: 'https://storage.googleapis.com/rmap_images/149/149_Yuna Cold Store_1_1473078477.jpeg',
    w: 1200,
    h: 900,
    title: '2016-09-05',
  },
  {
    thumbnail: `${process.env.PUBLIC_URL}/image/icons/ic_close.svg`,
    w: 100,
    h: 100,
    title: '2016-04-19',
    src: `${process.env.PUBLIC_URL}/image/icons/ic_close.svg`,
  },
  ]; */
    item.images = imageSliderListFormatter(item.images || []);
    return item;
  });

const dataMapper = (data = {}) => ({
  srnInvoiceNumber: data.SRNInvoiceNumber || '',
  summary: summaryDetailsMapper(data),
  lines: data.SRNDetails || [],
  // lines: data.SRNDetails ? lineMapper(data.SRNDetails) : [], /** for test purpose only **/
  srnAmount: data.srnAmount || totalPriceMapper({}),
  Distributor: data.Distributor || { serviceUsed: {} },
  paymentMode: data.paymentMode ?? PAYMENT_MODE?.CASH,
  customerName: data.customerName,
});

export { dataMapper as detailDataMapper, breadCrumb };
