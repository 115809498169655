import PropTypes from 'prop-types';
import { Component } from 'react';
import { crudSuccess as crudRequestConfig, breadCrumbConfig, title, detailsFormConfig, formMapper } from './config';
import { GRN } from '../../../../data/enums/Route';
import Table from './table';
import SummaryDetails from './summaryDetails';
import { clone } from '../../../../utils/arrayProcessor';
import { has } from '../../../../utils/objectPrototypes';
import { grnImageUploader } from '../../../../utils/image';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { RECORD_STATUS } from '../../../../data/enums/RecordStatus';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { getPermissionForGrn } from '../../../base/permission';
import { PanelCard, Button, Icon } from '../../../../v4/components';
import { getSubdUserStatusAndId, handlePrint } from '../../../common/HelperFunctions';
import GRNStyled from '../GRNStyled';
import { DOMAIN } from '../../../../data/enums/config';

const propTypes = {
  getSkus: PropTypes.func.isRequired,
  createGRN: PropTypes.func.isRequired,
  updateGRN: PropTypes.func.isRequired,
  approveGRN: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getVendors: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getGrnDetail: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  listMasterConfiguration: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class CreateGrn extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const { id = 0, exist = false } = getSubdUserStatusAndId();
    this.distributorId = id;
    this.distributorExist = exist;
    this.state = {
      skus: [],
      data: formMapper({}, this.distributorId),
      vendors: [],
      enableErrorDisplay: false,
      distributorBatchFlag: false,
      imageValidation: false,
      exciseInLine: false,
      distributorManualGRNEnabled: false,
      grnId: has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0,
      pending: has.call(props.match.params, 'type') ? props.match.params.type : '',
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      editable: false,
      approvable: false,
      backUpData: formMapper({}, this.distributorId),
      distributors: [],
      distributorSelectedId: 0,
      pendingStatus: false,
      isManual: false,
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createGRN,
      [EVENT_OPERATION.UPDATE]: props.updateGRN,
      [EVENT_OPERATION.APPROVE]: props.approveGRN,
    };
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.permission = getPermissionForGrn();
  }

  componentDidMount() {
    const { grnId, update, pending } = this.state;
    if (grnId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState(
        {
          grnId,
          update,
        },
        () => this.getGrnDetail(grnId),
      );
    }
    if (pending) {
      update.type = EVENT_OPERATION.APPROVE;
      update.status = true;
      this.setState(
        {
          grnId,
          update,
          pendingStatus: true,
        },
        () => this.getGrnDetail(grnId),
      );
    }

    this.getSKUs();
    this.getVendorsList();
    this.getDistributorsList();
    this.getlistMasterConfiguration();
  }

  getlistMasterConfiguration = () => {
    const { listMasterConfiguration, displayAlert } = this.props;
    listMasterConfiguration(
      { type: 'GRN_CONFIG' },
      {
        handleSuccess: res => {
          const grnValidationConfig = res.data.listMasterConfiguration.filter(a => a.type === 'GRN_CONFIG');
          this.setState({
            imageValidation: grnValidationConfig.length > 0 && grnValidationConfig[0].configuration.imageValidation,
            exciseInLine: grnValidationConfig.length > 0 && grnValidationConfig[0].configuration?.exciseInLine,
          });
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  getGrnDetail = id => {
    const { getGrnDetail, displayAlert } = this.props;
    getGrnDetail(
      { id },
      {
        handleSuccess: response => {
          const { findGRN = {} } = response.data;
          const editable = findGRN.isEditable && this.permission.update;
          const isManual = findGRN.isManual
          const approvable = this.permission.update && findGRN.status === RECORD_STATUS.RECEIVED;
          const mappedGRN = {...findGRN, grnDate : findGRN?.grnDate === null ? findGRN?.invoiceDate : findGRN?.grnDate, lines: findGRN.lines.map(a=>(
            {...a,
              priceDetails:{...a.priceDetails,
                exciseAmount: (a.priceDetails.exciseAmount) ? a.priceDetails.exciseAmount : 0
              },
              updatedPriceDetails:{...a.updatedPriceDetails,
                exciseAmount: (a.updatedPriceDetails.exciseAmount) ? a.updatedPriceDetails.exciseAmount : 0
              }
            }
            ))}
          this.setState({
            data: mappedGRN,
            backUpData: clone(mappedGRN),
            editable,
            approvable,
            isManual
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getVendorsList = () => {
    const { getVendors, displayAlert } = this.props;
    getVendors(
      {},
      {
        handleSuccess: response => {
          this.setState({ vendors: response.data.vendors.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setDistributorServices = distributor => {
    this.setState({
      distributorBatchFlag: distributor.batchImplementation,
      distributorManualGRNEnabled: distributor.enableManualGRN,
      distributorSelectedId: distributor.id,
    });
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          const distributors = response.data.distributors ? response.data.distributors.rows || [] : [];
          this.setState({ distributors }, () => {
            if (this.distributorExist && distributors.length > 0) {
              this.setDistributorServices(distributors[0]);
            }
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus(
      { includeAssortment: true },
      {
        handleSuccess: response => {
          this.setState({ skus: response.data.skus.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return (acc[value] = event.formattedValue);
          }
          return acc[value];
        }, data);
      } else {
        data[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      data[event.target.name] = event.formattedValue;
    }
    this.setState({ data });
  };

  onSubmit = type => {
    const valid = this.getValidationStatus();
    if (valid) {
      this.createGrn();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  getValidationStatus = () => {
    const detailsStatus = this.getDetailsValidationStatus();
    const tableStatus = this.getTableValidationStatus();

    return detailsStatus && tableStatus;
  };

  getActionType = () => {
    const { update } = this.state;
    return update.type !== EVENT_OPERATION.CREATE
      ? update.type === EVENT_OPERATION.UPDATE
        ? EVENT_OPERATION.UPDATE
        : EVENT_OPERATION.APPROVE
      : EVENT_OPERATION.CREATE;
  };

  createGrn = () => {
    const { data } = this.state;
    const images = this.getImages ? this.getImages() : [];
    data.images = images;
    const updatedData = clone(data);
    const type = this.getActionType();
    const tableData = this.getTableDetails();
    updatedData.details = this.getDetails();
    updatedData.lines = tableData.lines.map(line => ({
      ...line,
      warehouseLevelDetails:
        line.warehouseLevelDetails &&
        line.warehouseLevelDetails.map(d => ({
          warehouseLevelId: parseInt(d.warehouseLevelId, 10),
          quantity: d.quantity,
          binLocationNumber: d.binLocationNumber,
        })),
    }));
    updatedData.amount = tableData.amount;
    this.onFormSubmit(type, updatedData);
  };

  onAPIRequestFailure = error => {
    const { displayAlert, history } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    setTimeout(() => {
      if (error.message.includes('Billing Server')) {
        history.push(`/${GRN}`);
      }
    }, 1000);
    console.log(error);
  };

  responseProcessor = () => {
    const onAPIRequestSuccess = type => response => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage, response);
      // this.directToMainPage();
    };
    return onAPIRequestSuccess;
  };

  directToMainPage = response => {
    const { grnId, update, approvable } = this.state;
    const { history } = this.props;
    if (grnId) {
      let approveStatus = approvable;
      update.status = false;
      if (response.status === RECORD_STATUS.APPROVED) approveStatus = false;
      this.setState({ update, approvable: approveStatus });
      history.push(`/${GRN}`);
    } else history.push(`/${GRN}`);
  };

  handleCancelClick = () => {
    const { backUpData, grnId, update } = this.state;
    const { history } = this.props;
    if (grnId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState({ data: clone(backUpData), update });
    } else this.setState({ data: formMapper({}, this.distributorId) });
    history.push(`/${GRN}`);
  };

  handleProofClickOfDelivery = () => {
    const { createProofOfDelivery, displayAlert, history } = this.props;
    const {grnId} = this.state;
    createProofOfDelivery(
      { createProofOfDeliveryId: grnId },
      {
        handleSuccess: response => {
          displayAlert(ALERT_TYPE.SUCCESS, 'Submitted Successfully');
          setTimeout(() => {
            history.push(`/${GRN}`);
          }, 1200);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }


  handlePrintClick = () => {
    const { data } = this.state;
    handlePrint({
      module: DOMAIN.DBS_PURCHASE,
      invoiceNumber: data.invoiceNumber,
      distributorServices: data.Distributor.servicesUsed,
      newVersionCode: true,
      distributorId: data.Distributor.id,
    });
  };

  render() {
    const {
      data,
      skus,
      grnId,
      update,
      vendors,
      approvable,
      distributors,
      pendingStatus,
      enableErrorDisplay,
      distributorBatchFlag,
      distributorSelectedId,
      distributorManualGRNEnabled,
      imageValidation,
      exciseInLine,
      isManual,
    } = this.state;
    const { displayAlert, serverResponseWaiting, getSKUBatchDetail } = this.props;

    const crudMode = update.status ? EVENT_OPERATION_MAPPER[update.type] : '';
    
    return (
      <GRNStyled className="grn__create">
        <div className="section-header">
          <PanelStyled>
            <div className="prn-page-header">
              <PageHeader
                breadCrumb={breadCrumbConfig}
                title={grnId ? `${crudMode} GRN #${data.invoiceNumber}` : title}
              />
              <div className="flex m-0">
                <div>
                  {!pendingStatus && update.status && (
                    <>
                      <Button small secondary disabled={serverResponseWaiting} onClick={() => this.handleCancelClick()}>
                        <span>Cancel</span>
                      </Button>
                      <Button small primary disabled={serverResponseWaiting} onClick={() => this.onSubmit()}
                      onKeyDown={(e) => { (e.key === 'Enter' || e.code === 'Space') && e.preventDefault() }}
                      >
                        <span> Save </span>
                      </Button>
                    </>
                  )}
                </div>
                {grnId ? (
                  <>
                    {data?.Distributor?.servicesUsed?.billing?.status && (
                    <Button
                      iconBtnSmall
                      secondary
                      onClick={() => this.handlePrintClick()}
                      className="ml-16"
                    >
                      <Icon iconName="print" />
                    </Button>
                  )}

                    {pendingStatus && update.status && this.permission.create && (
                      <>
                        <Button
                          small
                          secondary
                          disabled={serverResponseWaiting}
                          onClick={() => this.handleCancelClick()}
                        >
                          <span>Cancel</span>
                        </Button>
                        <div className={data?.isBlocked?'audit-blocked':''}>
                        <Button
                          small
                          primary
                          className="ml-16"
                          disabled={data?.isBlocked || serverResponseWaiting}
                          onClick={() => {
                            this.onSubmit();
                          }}
                        >
                          Approve
                        </Button>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  ''
                )}
                {(grnId && !data?.isManual && !data.proofOfDelivery && !pendingStatus) ? (
                  <Button
                    small
                    primary
                    disabled={serverResponseWaiting}
                    onClick={() => this.handleProofClickOfDelivery()}
                  >
                    <span>Submit POD</span>
                  </Button>
                ):''}
              </div>
            </div>
          </PanelStyled>
        </div>

        <div className="section-content pad-48">
          <div>
            <PanelCard cardTitle="details" className={!update.status ? 'disabled' : ''}>
              <SummaryDetails
                update={update}
                pendingStatus={pendingStatus}
                isManual={isManual}
                enableErrorDisplay={enableErrorDisplay}
                updateDistributorServices={this.setDistributorServices}
                loading={serverResponseWaiting}
                vendorList={vendors}
                distributorList={distributors}
                formConfig={detailsFormConfig}
                dialogElement={data || {}}
                getImages={images => (this.getImages = images)}
                getStatus={childMethod => (this.getDetailsValidationStatus = childMethod)}
                getDetails={childMethod => (this.getDetails = childMethod)}
                distributorExist={this.distributorExist}
                imageValidation={imageValidation}
              />
            </PanelCard>
            <PanelCard cardTitle="Sku" skuClassStatus>
              <Table
                data={data}
                pendingStatus={pendingStatus}
                skuList={skus}
                update={update}
                imageUploader={grnImageUploader}
                displayAlert={displayAlert}
                enableErrorDisplay={enableErrorDisplay}
                getStatus={childMethod => (this.getTableValidationStatus = childMethod)}
                getDetails={childMethod => (this.getTableDetails = childMethod)}
                getSKUBatchDetail={getSKUBatchDetail}
                distributorBatchFlag={distributorBatchFlag}
                distributorManualGRNEnabled={distributorManualGRNEnabled}
                distributorSelectedId={distributorSelectedId}
                exciseInLine={exciseInLine}
              />
            </PanelCard>
          </div>
        </div>
      </GRNStyled>
    );
  }
}

CreateGrn.propTypes = propTypes;

CreateGrn.defaultProps = defaultProps;

export default withAlert()(CreateGrn);
