const REQUEST = {
  MUTATION: 'Mutation',
  QUERY: 'Query',
};

const getEnumFromObject = (object) => Object.freeze({ ...object });
const SCHEMAS = getEnumFromObject({
  SRNLineAmount: 'SRNLineAmount',
  SRNLines: 'SRNLines',
});

const UPLOADABLES = getEnumFromObject({
  SKU: 'SKU',
  GRN: 'GRN',
  TARGET: 'TARGET',
  SUBD: 'SUBD',
  OUTLET: 'OUTLET',
  LEDGER: 'LEDGER',
  OUTLET_CATEGORY: 'ROUTETOWNANDCATAGORYCHANGE',
  BRAND_TARGET: 'BRANDTARGET',
  OPENING_BALANCE: 'OPENINGBALANCE',
  REPLENISHMENT: 'PENDINGGRN',
  OUTLETCOVERAGETARGET: 'OUTLETCOVERAGETARGET',
  BRANDCOVERAGETARGET: 'BRANDCOVERAGETARGET',
  STOCKADJUSTMENT: 'STOCKADJUSTMENT',
  ICO: 'ICO',
  SUBDSKUTARGET: 'SUBDSKUTARGET',
  DEBOARD_OUTLET:'DEBOARDOUTLET'
});

const STATEFUL_ENTITIES = {
  USER: 'User',
  ROUTE: 'Route',
  CHANNEL: 'Channel',
  CATEGORY: 'Category',
  RETAIL_OUTLET: 'RetailOutlet',
  FOCUSED_SKU: 'FocusedSKU',
  SBD: 'SBD',
  MERCHANDISE_DEVICE: 'MerchandiseDevice',
  SKU: 'SKU',
  SKU_BATCH_RATE: 'SKUBatchRate',
  PROMOTION: 'Promotion',
  TOWN: 'Town',
  TERRITORY: 'Territory',
  Distributor: 'Distributor',
  SUB_D_ASSORTMENT: 'DistributorGroup',
  REPLENISHMENT: 'StockGroup',
  CATALOG_DETAILS: 'CatalogDetail',
  CASH: 'Cash',
  TRADE: 'Trade',
  LINE: 'Line',
  MESSAGE: 'Message',
  LEVELS: 'WarehouseLevel',
  WAREHOUSE: 'WarehouseLevelDetail',
  HIERARCHY: 'LocationHierarchy',
  LOCATION: 'LocationHierarchyDetail',
  SYSTEM_ALERT: 'SystemAlert',
  CAMPAIGN: 'Campaign',
  SUB_CAMPAIGN: 'SubCampaign',
  SUB_CAMPAIGN_DETAIL: 'SubCampaignDetail',
  PRESENTATION: 'Presentation',
  DESIGNATION:'Designation',
  STOCK_COUNT: 'StockCount',
  TARGET_DISTRIBUTION: 'TargetDistribution',
  SKUGroupMaster: 'SKUGroupMaster',
  SKUGroup: 'SKUGroup',
  ICOGroup: 'ICOGroup',
  StockIcoGroupDetail:'StockIcoGroupDetail',
};

const DOWNLOAD_DOMAIN = {
  SALES: 'sales',
  SALES_MV: 'materialized_view',
  PURCHASE: 'purchase',
  SALES_RETURN: 'sales_return',
  PURCHASE_RETURN: 'purchase_return',
  JOURNAL_VOUCHER: 'journal_voucher',
  VAT_LEDGER: 'vat_ledger',
  STOCK_LEDGER: 'STOCK_LEDGER',
  STOCK_JOURNAL: 'stock_journal',
  SALES_VAT: 'sales_vat',
  PURCHASE_VAT: 'purchase_vat',
  VAT_REPORT: 'vat_report',
  PURCHASE_RETURN_VAT: 'purchase_return_vat',
  NET_SALES_VAT: 'net_sales_vat',
  NET_PURCHASE_VAT: 'net_purchase_vat',
  SRN_VAT: 'srn_vat',
  LEDGER: 'ledger_report',
  AGEING_REPORT: 'ageing_report',
  PAYMENT: 'payment',
  DATABASE: 'database',
  OUTLET: 'retail_outlet',
  SUB_D_ASSORTMENT: 'Distributor Assortment',
  USER_TARGET_SAMPLE: 'USER_TARGET_SAMPLE',
  USER_BRAND_TARGET_SAMPLE: 'USER_BRAND_TARGET_SAMPLE',
  DISTRIBUTOR_TARGET_SAMPLE: 'DISTRIBUTOR_TARGET_SAMPLE',
  DISTRIBUTOR_BRAND_TARGET_SAMPLE: 'DISTRIBUTOR_BRAND_TARGET_SAMPLE',
  RECEIPT: 'Receipt',
  CHEQUES: 'PAYMENT_CHEQUE',
  POST_DATED_CHEQUE: 'PAYMENT_POST_DATED_CHEQUE',
  CASH: 'Cash',
  OUTSTANDING_REPORT: 'OUTSTANDING_REPORT',
  OUTSTANDING_DETAIL_REPORT: 'OUTSTANDING_DETAIL_REPORT',
  SALES_RETURN_REPORT: 'SALES_RETURN_REPORT',
  SKU_LIST_REPORT: 'SKU',
  OUTLET_COVERAGE_USER_TARGET_SAMPLE: 'OUTLET_COVERAGE_USER_TARGET_SAMPLE',
  BRAND_COVERAGE_USER_TARGET_SAMPLE: 'BRAND_COVERAGE_USER_TARGET_SAMPLE',
  TIME_ON_ROUTE: 'TIME_ON_ROUTE_REPORT',
  ATTENDANCE: 'ATTENDANCE_REPORT',
  CATEGORY_CHANGE: 'OUTLET_CATEGORY_CHANGE_APPROVAL',
  DEBOARD_OUTLET: 'OUTLET_DELETE_APPROVAL',
  ROUTE_TRANSFER: 'OUTLET_TRANSFER_APPROVAL',
  GEOLOCATION: 'OUTLET_GEOLOCATION_CHANGE_APPROVAL',
  BILLINGSKU: 'billingsku',
  ICO_REPORT: 'ico_report',
  DISTRIBUTOR_TARGET: 'DISTRIBUTOR_TARGET',
  DISTRIBUTOR_SKU_TARGET: 'DISTRIBUTOR_SKU_TARGET',
  USER_TARGET: 'USER_TARGET',
  OUTLET_COVERAGE: 'OUTLET_COVERAGE',
  OUTLET_FUNDAMENTAL_TARGET_SAMPLE:'OUTLET_FUNDAMENTAL_TARGET_SAMPLE',
  USER_FUNDAMENTAL_TARGET_SAMPLE:'USER_FUNDAMENTAL_TARGET_SAMPLE',
  DE_RETURN_REPORT: 'DE_RETURN_REPORT',
  TRIP_WISE_COLLECTION:'TRIP_WISE_COLLECTION',
  TARGET_DISTRIBUTION:'TARGET_DISTRIBUTION_REPORT',
  SBD_TARGET:'AUTOMATED_SBD_TARGET',
  CATALOG: 'CATALOG',
  SUB_D: 'SUB_D',
};

const TYPENAME = '__typename';

const PROMOTION_TYPES = {
  NORMAL: 'NORMAL',
  CURRENT_BILL: 'CURRENT_BILL',
  TOP_UP: 'TOP_UP',
  NEXT_BILL: 'NEXT_BILL',
  DIRECT_REIMBURSEMENT: 'DIRECT_REIMBURSEMENT',
};

const PROMOTION_SCOPE = {
  SINGLE: 'SINGLE',
  GROUP: 'GROUP',
};

const IMAGE_UPLOAD_DOMAIN = {
  SKU: 'skus',
  CATALOGS: 'catalogs',
  RECEIPTS: 'receipts',
  PROFILE: 'profile',
  PROMOTIONS: 'promotion',
  GRN: 'grn',
};

const COVERAGE_TYPE = {
  CHANNEL: 'CHANNEL',
  CATEGORY: 'CATEGORY',
  SUB_D: 'DISTRIBUTOR',
  ROUTE: 'ROUTE',
  BRAND: 'BRAND',
};

const ORDER_HISTORY_VIEW = {
  BRAND: 'BRAND',
  DATE: 'DATE',
};

export {
  REQUEST,
  SCHEMAS,
  STATEFUL_ENTITIES,
  UPLOADABLES,
  PROMOTION_TYPES,
  PROMOTION_SCOPE,
  TYPENAME,
  DOWNLOAD_DOMAIN,
  IMAGE_UPLOAD_DOMAIN,
  COVERAGE_TYPE,
  ORDER_HISTORY_VIEW,
};
