import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../../common/GqlAPI';

const GET_TARGETS_LIST = gql`
query GetAutomatedSalesTargetList($limit: Int, $offset: Int, $filter: FilterInput) {
  getAutomatedSalesTargetList(limit: $limit, offset: $offset, filter: $filter) {
    count
    rows {
      average_base {
        is_same_quarter
      }
      id
      month
      national_target
      year
      monthName
    }
  }
}
`;

const query = {
  getTargetList: GET_TARGETS_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
};

export { GET_TARGETS_LIST, TOGGLE_STATE };

export { query, mutation };
