import gql from 'graphql-tag';

const SKU_TITLE_FRAGMENT = gql`
  fragment SkuFragment on SKU {
    id
    title
  }
`;

const DISTRIBUTOR_FRAGMENT = gql`
  fragment DistributorFragment on Distributor {
    id
    title
  }
`;

const DISTRIBUTOR_SERVICES_FRAGMENT = gql`
  fragment DistributorServicesFragment on Distributor {
    id
    title
    batchImplementation
    enableManualGRN
    enableDnEApproval
    zeroDayInventory
    Towns {
      id
    }
    servicesUsed {
      logistic {
        status
      }
      delivery {
        status
      }
      billing {
        status
        url
        versionCode
      }
    }
  }
`;

const ROUTE_TITLE_FRAGMENT = gql`
  fragment RouteTitleFragment on Route {
    id
    title
  }
`;

const VENDOR_FRAGMENT = gql`
  fragment VendorFragment on Vendor {
    id
    name
  }
`;

const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on Image {
    image
    thumbnail
    created_at
  }
`;

const LINE_PRICE_FRAGMENT = gql`
  fragment LinePriceFragment on LinePrice {
    rate
    amount
    discount
    netAmount
    taxAmount
    billDiscount
    tradeDiscount
    exciseAmount
  }
`;

const LOCATION_FRAGMENT = gql`
  fragment LocationFragment on Location {
    latitude
    longitude
  }
`;

const OUTLET_CATEGORY_FRAGMENT = gql`
  fragment OutletCategoryFragment on RetailOutlet {
    id
    title
    address
    panNumber
    Category {
      id
      title
      Channel {
        id
        title
      }
    }
    Route {
      ...RouteTitleFragment
    }
  }
  ${ROUTE_TITLE_FRAGMENT}
`;

const USER_TITLE_FRAGMENT = gql`
  fragment UserTitleFragment on User {
    id
    fullName
  }
`;

const OUTLET_TITLE_FRAGMENT = gql`
  fragment OutletTitleFragment on RetailOutlet {
    id
    title
  }
`;

const OUTLET_CUSTOMER_FRAGMENT = gql`
  fragment OutletCustomerFragment on RetailOutlet {
    id
    title
    Customers {
      id
      name
      panNumber
      address
      distributorId
      creditLimit
      creditDay
      billingLedgerId
    }
  }
`;

const OUTLET_FRAGMENT_WITHOUT_OUTSTANDING_TRANSACTION_ID = gql`
    fragment OutletCustomerFragment on RetailOutlet {
        id
        title
        panNumber
        address
        Customers {
            id
            name
            panNumber
            address
            distributorId
            creditLimit
            creditDay
            billingLedgerId
    
        }
        contactInfo {
          phoneNumber
        }
    }
`;

const AMOUNT_DETAIL_FRAGMENT = gql`
  fragment AmountDetailFragment on AmountDetail {
    rate
    grossAmount
    netAmount
    taxAmount
    taxableAmount
    subTotal
    billDiscount
    tradeDiscount
    discountAmount
    promotionDiscount
    topUpDiscount
  }
`;

const CUSTOMER_FRAGMENT = gql`
  fragment CustomerFragment on Customer {
    id
    name
    address
    panNumber
    distributorId
  }
`;

const OUTLET_CUSTOMER_WITH_OUTSTANDING_FRAGMENT = gql`
  fragment OutletCustomerFragment on RetailOutlet {
    id
    title
    Customers {
      id
      name
      panNumber
      address
      distributorId
      creditLimit
      creditDay
      outstandingAmount(distributorId: $distributorId, retailOutletId: $retailOutletId)
      billingLedgerId
    }
  }
`;

const PROMOTION_TITLE_FRAGMENT = gql`
  fragment PromotionTitleFragment on Promotion {
    id
    title
  }
`;

const FILE_FORMAT_FRAGMENT = gql`
  fragment fileFormatFragment on fileFormat {
    path
    name
  }
`;

const GEO_LOCATION_FRAGMENT = gql`
  fragment GeoLocationFragment on Location {
    latitude
    longitude
  }
`;

const CALL_ORDER_VALUE_FRAGMENT = gql`
  fragment CallOrderValueFragment on CallOrderValue {
    discountAmount
    billDiscount
    netAmount
    grossAmount
    topUpDiscount
    subTotal
  }
`;

/* billDiscountAmount */

const BILL_DISCOUNT_DETAILS_FRAGMENT = gql`
  fragment BillDisocuntDetailsFragment on BillDiscountData {
    id
    amount
    orderIds
    title
  }
`;

export {
  IMAGE_FRAGMENT,
  VENDOR_FRAGMENT,
  LOCATION_FRAGMENT,
  CUSTOMER_FRAGMENT,
  SKU_TITLE_FRAGMENT,
  LINE_PRICE_FRAGMENT,
  USER_TITLE_FRAGMENT,
  FILE_FORMAT_FRAGMENT,
  ROUTE_TITLE_FRAGMENT,
  DISTRIBUTOR_FRAGMENT,
  OUTLET_TITLE_FRAGMENT,
  GEO_LOCATION_FRAGMENT,
  AMOUNT_DETAIL_FRAGMENT,
  OUTLET_CUSTOMER_FRAGMENT,
  OUTLET_CATEGORY_FRAGMENT,
  PROMOTION_TITLE_FRAGMENT,
  CALL_ORDER_VALUE_FRAGMENT,
  DISTRIBUTOR_SERVICES_FRAGMENT,
  BILL_DISCOUNT_DETAILS_FRAGMENT,
  OUTLET_CUSTOMER_WITH_OUTSTANDING_FRAGMENT,
  OUTLET_FRAGMENT_WITHOUT_OUTSTANDING_TRANSACTION_ID,
};
