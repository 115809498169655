import React, { Component } from 'react';
import Proptypes from 'prop-types';
import withAlert from '../../../utils/composition/withAlert';
import View from './View';
import { inputChange } from '../../../utils/formHandlers';
import BuStyled from '../../configuration/bu/BuStyled';
import { clone } from '../../../utils/objectProcessor';
import { hyphenPresentor } from '../../../utils/date';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { refGenerator } from '../../../utils';

const staticData = {
  weekendDetails: [],
  holidayCount: {
    casual: 0,
    sick: 0,
    home: 0,
    unpaid: 0,
  },
  holidayDetails: [
    {
      title: '',
      start: new Date(),
      end: new Date(),
    },
  ],
};

const propTypes = {
  serverResponseWaiting: Proptypes.bool,
  replicateHoliday: Proptypes.bool,
  updateCalendar: Proptypes.func.isRequired,
  getCalendarDetail: Proptypes.func.isRequired,
  createCalendar: Proptypes.func.isRequired,
  callHolidaySection: Proptypes.bool.isRequired,
  params: Proptypes.shape(Object),
};

const defaultProps = {
  serverResponseWaiting: false,
  params: {},
  replicateHoliday: false,
};
class HolidaysSection extends Component {
  constructor(props) {
    super(props);
    const { params } = props;
    const initialData = {
      ...staticData,
      ...{
        domain: params.id ? 'DISTRIBUTOR' : 'BU',
        distributorId: params.id ? parseInt(params.id, 10) : null,
      },
      replicate: false,
      enableErrorDisplay: false,
    };
    this.state = {
      data: { ...initialData },
      subDistId: 0,
      subDistIdCloned: 0,
      dataCloned: { ...initialData },
    };
    this.formReference = refGenerator(['title']);
  }
  componentDidMount() {
    const { data } = this.state;
    const { domain, distributorId } = data;
    this.getHolidaysInfo({ domain, distributorId });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data, dataCloned } = this.state;
    const { getHolidayData, resetDataHoliday } = this.props;
    if (resetDataHoliday) {
      this.setState({ data: dataCloned });
    }
    if (prevState?.data !== data) {
      !!getHolidayData && getHolidayData(data, dataCloned);
    }
    const { callHolidaySection } = this.props;
    const { replicate } = this.state;

    if (callHolidaySection !== prevProps.callHolidaySection) {
      this.createHolidays();
    }

    if (replicate !== prevState.replicate) {
      const data = {
        domain: 'BU',
        distributorId: null,
      };
      this.getHolidaysInfo(data);
    }
  }

  getHolidaysInfo = dataParam => {
    const { getCalendarDetail, params } = this.props;
    getCalendarDetail(
      { input: dataParam },
      {
        handleSuccess: response => {
          const { getCalendar } = response.data;
          const subDistIdUpdated = getCalendar.domain === 'DISTRIBUTOR' ? { subDistId: getCalendar.id } : {};
          this.setState({
            data: getCalendar,
            dataCloned: clone(getCalendar),
            subDistId: subDistIdUpdated,
            subDistIdCloned: clone(subDistIdUpdated),
          });
        },
      },
    );
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const { getDetails } = this.props;
    if (!event.target.checked && paramList.length === 0) {
      this.setState({
        data: {
          ...data,
          ...{
            weekendDetails: data.weekendDetails.filter(d => d !== event.formattedValue),
          },
        },
      });
    } else {
      const updatedDetails = inputChange(data, event, firstParam, paramList);
      if (data.id !== 0 && data.id !== null && paramList.length > 2) {
        updatedDetails.holidayDetails[paramList[1].toString()].edited = true;
      }
      this.setState({ data: updatedDetails });
    }
  };

  handleDateRangeChange = (name, date, index) => {
    const { data, dataCloned } = this.state;
    const { getHolidayData } = this.props;
    const updatedData = clone(data);

    updatedData.holidayDetails[index] = {
      ...updatedData.holidayDetails[index],
      [name]: hyphenPresentor(date),
    };
    this.setState(
      {
        data: updatedData,
      },
      () => {
        !!getHolidayData && getHolidayData(updatedData, dataCloned);
      },
    );
  };

  handleHolidaysAdd = () => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{
          holidayDetails: [...data.holidayDetails, ...staticData.holidayDetails],
        },
      },
    });
  };

  handleCancelHoliday = title => {
    const { data } = this.state;
    const { holidayDetails } = data;
    const filterData = [...holidayDetails].filter(d => d.title !== title);
    this.setState({
      data: {
        ...data,
        ...{
          holidayDetails: filterData,
        },
      },
    });
  };
  getValidationStatus = () => !Object.values(this.formReference).find(item => item?.getValidState() === false);
  createHolidays = () => {
    const { updateCalendar, createCalendar, params, getHolidayData, displayAlert } = this.props;
    const { subDistId, data: mainData } = this.state;
    const { id, ...data } = mainData;
    const createData = {
      ...data,
      domain: params.id ? 'DISTRIBUTOR' : 'BU',
      distributorId: params.id ? parseInt(params.id, 10) : null,
    };
    const createId = params.id ? subDistId : id;
    const valid = this.getValidationStatus();
    if (mainData?.holidayDetails?.length === 0) {
      displayAlert(ALERT_TYPE.DANGER, { message: 'At least one public holiday is required.' });
      return;
    }
    if (!valid) {
      this.setState({ enableErrorDisplay: true });
      return;
    }
    if (createId) {
      updateCalendar(
        { id: createId, input: createData },
        {
          handleSuccess: response => {
            const { updateCalendar: updateCalendarData } = response.data;
            this.setState(
              {
                data: updateCalendarData,
              },
              () => {
                !!getHolidayData && getHolidayData(data, data);
              },
            );
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    } else {
      createCalendar(
        { input: createData },
        {
          handleSuccess: response => {
            const { createCalendar: createCalendarData } = response.data;
            this.setState(
              {
                data: createCalendarData,
              },
              () => {
                !!getHolidayData && getHolidayData(data, data);
              },
            );
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    }
  };
  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };
  toggleToReplicate = toggleState => {
    this.setState(state => ({
      replicate: toggleState,
      ...state.data,
    }));
  };
  render() {
    const { data, replicate, enableErrorDisplay } = this.state;
    const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const { serverResponseWaiting, replicateHoliday, className } = this.props;
    return (
      <BuStyled className={className}>
        <View
          holidayData={data}
          week={week}
          loading={serverResponseWaiting}
          handleInputChange={this.handleInputChange}
          handleCancelHoliday={this.handleCancelHoliday}
          handleDateRangeChange={this.handleDateRangeChange}
          handleHolidaysAdd={this.handleHolidaysAdd}
          toggleToReplicate={this.toggleToReplicate}
          replicate={replicate}
          replicateHoliday={replicateHoliday}
          refsObj={this.formReference}
          enableErrorDisplay={enableErrorDisplay}
        />
      </BuStyled>
    );
  }
}

HolidaysSection.propTypes = propTypes;

HolidaysSection.defaultProps = defaultProps;

export default withAlert()(HolidaysSection);
