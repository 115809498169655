import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form, CustomSelect } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { Col, Row } from 'react-flexbox-grid';
import { EMPTY } from '../../../data/enums/ErrorMessage';

const propTypes = {
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  binList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  refsObj: {},
  binList: [],
  handleDropDownChange: () => null,
  handleDropDown: () => null,
  locationHierarchyList: () => null,
};

const TransferForm = ({ ...props }) => {
  const {
    data,
    enableErrorDisplay,
    handleInputChange,
    refsObj,
    binList,
    handleDropDownChange,
    handleDropDown,
    activeAccordion,
    element,
  } = props;
  const { closing_sellable, closing_damages, closing_expiry } = activeAccordion?.child || {};
  const notDecimal = /^[0-9]+$/;
  return (
    <Form>
      <Row>
        <Col lg={12} className="mb-24">
          <div className="item-wrap">
            <div className="sku-wrap">
              <label htmlFor="">Item</label>
              <p className="sku-title">{data.sku}</p>
            </div>
            <div className="label-wrap">
              <label htmlFor="" className="lab">
                Bin Location: <span>{activeAccordion?.parent?.bin_code}</span>
              </label>
              <div className="multiple-label">
                <label htmlFor="" className="lab">
                  Saleable: <span>{closing_sellable}</span> /{' '}
                </label>
                <label htmlFor="" className="lab">
                  {' '}
                  Damaged: <span>{closing_damages}</span> /{' '}
                </label>
                <label htmlFor="" className="lab">
                  {' '}
                  Expired: <span>{closing_expiry}</span>
                </label>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} className="mb-24">
          <Input
            ref={ref => (refsObj.batchName = ref)}
            value={data.batchName}
            name="batchName"
            type="text"
            placeholder="Batch"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event)}
            labelContent="Batch"
            disabled
          />
        </Col>
        <Col lg={12} className="mb-24">
          <div className="quantity">
            <Row>
              <Col lg={4}>
                <Input
                  labelContent="Saleable QTY"
                  ref={ref => (refsObj.saleable = ref)}
                  value={data.saleable}
                  name="saleable"
                  type="text"
                  placeholder="Saleable"
                  onChange={e => {
                    handleInputChange(e);
                    handleDropDown(e?.target?.value, ['saleable']);
                  }}
                  enableValidation
                  decimalWithPrecedingZero
                  rule="isFloat"
                  argument={{
                    min: 0,
                    allow_leading_zeroes: false,
                  }}
                  enableErrorDisplay={enableErrorDisplay}
                  externalValidation={
                    !(
                      data.saleable > closing_sellable ||
                      !notDecimal?.test?.(data.saleable) ||
                      (data.saleable <= 0 && element?.newBinIdSaleable !== null)
                    )
                  }
                  errorMessage={
                    data.saleable > closing_sellable
                      ? `Max. ${closing_sellable}`
                      : data.saleable <= 0 && element?.newBinIdSaleable !== null
                      ? 'Min. 0'
                      : !notDecimal?.test?.(data.saleable) && data.saleable !== ''
                      ? 'Quantity invalid'
                      : EMPTY
                  }
                />
              </Col>
              <Col lg={8} className="mb-24">
                <CustomSelect
                  clearable={false}
                  className="custom-select"
                  placeholder="New Bin Location"
                  getOptionLabel={({ bin_code }) => bin_code}
                  getOptionValue={({ bin_location_id }) => bin_location_id}
                  options={binList?.filter(a => a?.bin_location_id !== activeAccordion?.parent?.bin_location_id)}
                  value={binList.filter(({ bin_location_id }) => bin_location_id === data.newBinIdSaleable)}
                  onChange={e => {
                    handleDropDownChange(e?.bin_location_id, ['newBinIdSaleable']);
                    handleDropDown(e?.bin_location_id, ['newBinIdSaleable']);
                  }}
                  isClearable
                  labelContent="New Bin Location"
                />
              </Col>
            </Row>
          </div>
          <div className="quantity">
            <Row>
              <Col lg={4}>
                <Input
                  labelContent="Damaged QTY"
                  ref={ref => (refsObj.damaged = ref)}
                  value={data.damaged}
                  name="damaged"
                  type="text"
                  placeholder="Damaged"
                  onChange={e => {
                    handleInputChange(e);
                    handleDropDown(e?.target?.value, ['damaged']);
                  }}
                  enableValidation
                  decimalWithPrecedingZero
                  rule="isFloat"
                  argument={{
                    min: 0,
                    allow_leading_zeroes: false,
                  }}
                  enableErrorDisplay={enableErrorDisplay}
                  externalValidation={
                    !(
                      (data.damaged <= 0 && element?.newBinIdDamaged !== null) ||
                      data.damaged > closing_damages ||
                      !notDecimal?.test?.(data.damaged)
                    )
                  }
                  errorMessage={
                    data.damaged > closing_damages
                      ? `Max. ${closing_damages}`
                      : data.damaged <= 0 && element?.newBinIdDamaged !== null
                      ? 'Min. 0'
                      : !notDecimal?.test?.(data.damaged) && data.damaged !== ''
                      ? 'Quantity invalid'
                      : EMPTY
                  }
                />
              </Col>
              <Col lg={8} className="mb-24">
                <CustomSelect
                  clearable={false}
                  className="custom-select"
                  placeholder="New Bin Location"
                  getOptionLabel={({ bin_code }) => bin_code}
                  getOptionValue={({ bin_location_id }) => bin_location_id}
                  options={binList?.filter(a => a?.bin_location_id !== activeAccordion?.parent?.bin_location_id)}
                  value={binList.filter(({ bin_location_id }) => bin_location_id === data.newBinIdDamaged)}
                  onChange={e => {
                    handleDropDownChange(e?.bin_location_id, ['newBinIdDamaged']);
                    handleDropDown(e?.bin_location_id, ['newBinIdDamaged']);
                  }}
                  isClearable
                  labelContent="New Bin Location"
                />
              </Col>
            </Row>
          </div>
          <div className="quantity">
            <Row>
              <Col lg={4}>
                <Input
                  labelContent="Expired QTY"
                  ref={ref => (refsObj.expired = ref)}
                  value={data.expired}
                  name="expired"
                  type="text"
                  placeholder="Expired"
                  onChange={e => {
                    handleInputChange(e);
                    handleDropDown(e?.target?.value, ['expired']);
                  }}
                  enableValidation
                  decimalWithPrecedingZero
                  rule="isFloat"
                  argument={{
                    min: 0,
                    allow_leading_zeroes: false,
                  }}
                  enableErrorDisplay={enableErrorDisplay}
                  externalValidation={
                    !(
                      data.expired > closing_expiry ||
                      !notDecimal?.test?.(data.expired) ||
                      (data.expired <= 0 && element?.newBinIdExpired !== null)
                    )
                  }
                  errorMessage={
                    data.expired > closing_expiry
                      ? `Max. ${closing_expiry}`
                      : data.expired <= 0 && element?.newBinIdExpired !== null
                      ? 'Min. 0'
                      : !notDecimal?.test?.(data.expired) && data.expired !== ''
                      ? 'Quantity invalid'
                      : EMPTY
                  }
                />
              </Col>
              <Col lg={8} className="mb-24">
                <CustomSelect
                  clearable={false}
                  className="custom-select"
                  placeholder="New Bin Location"
                  getOptionLabel={({ bin_code }) => bin_code}
                  getOptionValue={({ bin_location_id }) => bin_location_id}
                  options={binList?.filter(a => a?.bin_location_id !== activeAccordion?.parent?.bin_location_id)}
                  value={binList.filter(({ bin_location_id }) => bin_location_id === data.newBinIdExpired)}
                  onChange={e => {
                    handleDropDownChange(e?.bin_location_id, ['newBinIdExpired']);
                    handleDropDown(e?.bin_location_id, ['newBinIdExpired']);
                  }}
                  isClearable
                  labelContent="New Bin Location"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

TransferForm.propTypes = propTypes;

TransferForm.defaultProps = defaultProps;

const TransferViewWithErrorAndLoading = withLoading(TransferForm);

export default TransferViewWithErrorAndLoading;
