import React from 'react';
import { Button, MonthPicker } from '../../../../v4/components';
import { Col, CustomSelect, Input, Row } from '../../../../components';
import { AVERAGE_BASE } from './config';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { NewTableBody, NewTableHeader } from './NewTableConfig';
import { Summary } from './Summary';

const NewView = props => {
  const {
    read,
    update,
    data,
    handleCalculateTarget,
    handleDateChange,
    handleInputChange,
    refsObj,
    enableErrorDisplay,
    calculateClicked,
    loading,
    id,
    permission,
    onSelectChange,
    handleInputChangeTable,
    principalList,
    dimensionList,
    newData,
    locationEnabled,
    listData,
  } = props;
  const CalculateButton = () => {
    return (
      <Button medium secondary onClick={() => handleCalculateTarget()} className="targetBtn" disabled={loading}>
        <span>Calculate Target</span>
      </Button>
    );
  };
  const list = locationEnabled ? 'zoneList' : 'list';
  return (
    <section className="new-view">
      <div className={read ? 'field-section disabled' : 'field-section'}>
        <Row>
          <Summary
            principalList={principalList}
            enableErrorDisplay={enableErrorDisplay}
            refsObj={refsObj}
            update={update}
            read={read}
            onSelectChange={onSelectChange}
            handleDateChange={handleDateChange}
            data={data}
            calculateClicked={calculateClicked}
            handleInputChange={handleInputChange}
            listData={listData}
          />
          {read || (
            <Col lg={12}>
              <div className="mt-24">
                {id && permission.update ? <CalculateButton /> : ''}

                {!id && permission.create ? <CalculateButton /> : ''}
              </div>
            </Col>
          )}
        </Row>
      </div>
      {data?.[list]?.length > 0 && (
        <div className={`table-wrap prn ${read ? 'disabled' : ''}`}>
          <table>
            {NewTableHeader({ locationEnabled })}

            <tbody>
              {data?.[list]?.map(target =>
                NewTableBody({
                  data: target,
                  locationEnabled,
                  handleInputChange: handleInputChangeTable,
                  read,
                }),
              )}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};
const NewViewWithErrorAndLoading = withErrorAndLoadingScreen(NewView);

export default NewViewWithErrorAndLoading;
