import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import Form from './Form';
import {
  extractSingleItemFromList, filterItems,
  findAndReplaceItem,
} from '../../../../../utils/arrayProcessor';
import { totalPriceMapper } from '../config';
import { TableView, TotalView } from './View';
import { DialogFormWrapper } from '../../../../common';
import { RETURN_TYPE } from '../../../../common/DomainConfig';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import {
  calculateRelativeDiscount, formConfig, getLineAmountDetails,
  qtyType,
  STOCK_TYPE_LIST, totalViewRefs, updateSkuLine,
} from './config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { ORDER_SELECTION_ERROR } from '../../../../../data/enums/ErrorMessage';
import Confirmation from '../../../../common/DialogConfirmation';
import {
  getCheckboxStatus,
  isConfirmationType,
  updateSkuUsageDates,
} from '../../../../common/HelperFunctions';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { getUser } from '../../../../../data/dao';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  displayAlert: PropTypes.func.isRequired,
  getDetails: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  skuList: PropTypes.instanceOf(Array),
  update: PropTypes.instanceOf(Object),
  invoiceNumberStatus: PropTypes.bool,
  permission: PropTypes.instanceOf(Object),
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  data: {
    lines: [],
    amount: {},
  },
  skuList: [],
  serverResponseWaiting: false,
  invoiceNumberStatus: false,
  permission: PERMISSION_OBJ,
};

const baseAmountDetails = {
  billDiscount: 0,
  tradeDiscount: 0,
  taxAmount: 0,
  taxableAmount: 0,
  subTotal: 0,
  total: 0,
};

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        lines: [],
        amount: { ...baseAmountDetails },
        updatedAmount: { ...baseAmountDetails },
        inputInvoiceNumber: '',
      },
      dialog: {
        type: '',
        element: '',
      },
      selectedOrders: [],
      skuBatchList: [],
      totalAmount: totalPriceMapper({}),
      enableErrorDisplay: false,
      sellableValidation: false,
      damagesValidation: false,
      shortagesValidation: false,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    // eslint-disable-next-line max-len
    const inputInvoiceNumberUpdated = nextProps.data.inputInvoiceNumber !== state.data.inputInvoiceNumber;

    if (inputInvoiceNumberUpdated && !nextProps.invoiceNumberStatus) {
      return {
        data: {
          lines: [],
          amount: {
            ...baseAmountDetails,
          },
          updatedAmount: {
            ...baseAmountDetails,
          },
          inputInvoiceNumber: nextProps.data.inputInvoiceNumber,
        },
        totalAmount: totalPriceMapper({}),
        enableErrorDisplay: false,
      };
    }

    if (nextProps.invoiceNumberStatus && !inputInvoiceNumberUpdated) {
      return {
        data: nextProps.data,
      };
    }

    if (nextProps.invoiceNumberStatus && inputInvoiceNumberUpdated) {
      return {
        data: nextProps.data,
        totalAmount: totalPriceMapper({}),
        enableErrorDisplay: false,
        selectedOrders: [],
      };
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  handleFormSubmit = (type, dialogData) => {
    const { damagesValidation, shortagesValidation, sellableValidation } = this.state;
    const { displayAlert, invoiceNumberStatus } = this.props;
    if (!sellableValidation && !shortagesValidation && !damagesValidation) {
      if (dialogData.updatedQuantity === 0) {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Sum of quantity should not be 0');
          return
      }
      if (invoiceNumberStatus) {
        if (dialogData.updatedQuantity > dialogData.updatedQuantityReference) {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, `Sum of quantity should not be greater ${dialogData.updatedQuantityReference} `);
          return
        }
      }
      if (type === EVENT_OPERATION.DELETE) {
        this.handleRowDelete(dialogData);
      } else {
        this.handleDataUpdate(dialogData);
      }
    }
  };

  handleDataUpdate = (dialogData) => {
    const { data } = this.state;
    const { invoiceNumberStatus } = this.props;

    const modifiedDialogData = this.modifyData(dialogData);
    if (modifiedDialogData.id) {
      data.lines = findAndReplaceItem(data.lines, modifiedDialogData);
    } else {
      modifiedDialogData.id = 1000 + data.lines.length + 1;
      data.lines.push(modifiedDialogData);
    }

    this.setState({ data }, () => {
      if (invoiceNumberStatus) {
        this.handleSecondaryCheckboxClick(modifiedDialogData);
      } else {
        this.calculateLineTotal();
      }
    });
    this.resetDialog();
  };

  handleRowDelete = (dialogData) => {
    const { data } = this.state;
    const index = data.lines.findIndex(item => item.id === dialogData.id);
    data.lines.splice(index, 1);
    this.setState({ data }, () => this.calculateLineTotal());
    this.resetDialog();
  };

  modifyData = (dialogData) => {
    const { skuList, invoiceNumberStatus } = this.props;
    const { skuBatchList, data, totalAmount } = this.state;
    const referenceTotalAmount = invoiceNumberStatus ? data.amount : totalAmount;
    const selectedSku = filterItems(skuList, dialogData.skuId)[0] || {};
    const selectedBatch = filterItems(skuBatchList, dialogData.skuBatchId)[0] || {};
    const updateDialogData = updateSkuLine(
      dialogData, selectedSku, selectedBatch, referenceTotalAmount,
    );

    return updateDialogData;
  };

  determineReturnType = () => {
    const { data, selectedOrders } = this.state;
    const { invoiceNumberStatus } = this.props;
    if (invoiceNumberStatus) {
      if (data.lines.length !== selectedOrders.length) {
        return RETURN_TYPE.PARTIAL;
      }
      const prnType = data.lines.some(item => item.updatedQuantity !== item.referenceQuantity)
        ? RETURN_TYPE.PARTIAL : RETURN_TYPE.COMPLETE;
      return prnType;
    }

    return RETURN_TYPE.COMPLETE;
  };

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
    const user = getUser();
    this.getSKUBatchList(element.skuId, user.Distributor[0].id);
  };

  getSKUBatchList = (skuId, distributorId) => {
    const { getSKUBatchDetail } = this.props;
    if (skuId) {
      getSKUBatchDetail(
        {
          distributorId,
          skuId,
        }, {
        handleSuccess: (response) => {
          this.setState({ skuBatchList: response.data.getSkuBatchDetails });
        },
        handleError: (err) => {
          // TODO call the api error function.
          // this.onAPIRequestFailure(err);
        },
      },
      );
    }

  };

  onDropDownChange = (item, value, state, stateUpdater) => {
    const { skuList } = this.props;
    const { skuBatchList } = this.state;

    if (item === 'skuId') {
      const selectedSku = filterItems(skuList, value)[0] || {};
      // eslint-disable-next-line no-param-reassign
      // state.updatedPriceDetails.rate = selectedSku.Rates[0].priceDetails.rlp;
      state.skuBatchId = null;
      state.updatedPriceDetails.rate = 0;
      stateUpdater(state);

      const user = getUser();
      this.getSKUBatchList(value, user.Distributor[0].id);
    }
    if (item === 'skuBatchId') {
      const selectedBatch = skuBatchList.find(({ id }) => id === value);

      state.updatedPriceDetails.rate = selectedBatch ? selectedBatch.dlp : 0;
      state.sellableStock = selectedBatch ? selectedBatch.sellableStock : 0;
      state.damagedStock = selectedBatch ? selectedBatch.damagedStock : 0;
      state.expiredStock = selectedBatch ? selectedBatch.expiredStock : 0;
      state.shortageStock = selectedBatch ? selectedBatch.shortageStock : 0;

      const usageDate = {
        manufacture: selectedBatch
          ? selectedBatch.manufactureDate : new Date().toISOString().slice(0, 10),
        expiry: selectedBatch
          ? selectedBatch.expiryDate : new Date().toISOString().slice(0, 10),
      };
      updateSkuUsageDates(state, stateUpdater, usageDate);
    }
  };

  handleDiscountChange = (e) => {
    const { totalAmount } = this.state;
    totalAmount[e.target.name] = e.formattedValue;
    this.calculateTotal(totalAmount);
  };

  onInputChange = (item, value, state, stateUpdater) => {
    const { invoiceNumberStatus } = this.props;
    if (item === 'damages') {
      this.checkSkuStockStatus(state, item)
        .then((res) => {
          if (res) {
            if (invoiceNumberStatus) {
              if (value > state.updatedDamages) {
                this.setState({ damagesValidation: true });
              } else {
                this.setState({ damagesValidation: false });
              }
            }
            state.updatedQuantity = state.sellable + state.damages + state.shortages + state.expiry;
            const referenceAmount = state.priceDetails ? state.priceDetails.amount || 1 : 1;
            const referenceDiscount = state.priceDetails ? state.priceDetails.discount || 0 : 0;
            const referenceExciseAmount = state.priceDetails ? state.priceDetails.exciseAmount || 0 : 0;
            const amount = state.updatedQuantity * state.updatedPriceDetails.rate || 0;
            state.updatedPriceDetails.discount = calculateRelativeDiscount(
              referenceAmount, referenceDiscount, amount,
            );
            state.updatedPriceDetails.exciseAmount = calculateRelativeDiscount(
              referenceAmount, referenceExciseAmount, amount,
            );
            stateUpdater(state);
          } else {
            state.shortages = state.updatedShortages;
            state.damages = state.updatedDamages;
            state.sellable = state.updatedSellable;
            state.updatedQuantity = state.sellable + state.damages + state.shortages + state.expiry;
            state.updatedPriceDetails.discount = state.updatedDiscount || 0;
            state.updatedPriceDetails.exciseAmount = state.updatedExciseAmount || 0;
            stateUpdater(state);
          }
        });
    }
    if (item === 'shortages') {
      this.checkSkuStockStatus(state, item)
        .then((res) => {
          if (res) {
            if (invoiceNumberStatus) {
              if (value > state.updatedShortages) {
                this.setState({ shortagesValidation: true });
              } else {
                this.setState({ shortagesValidation: false });
              }
            }
            state.updatedQuantity = state.sellable + state.damages + state.expiry + state.shortages;
            const referenceAmount = state.priceDetails ? state.priceDetails.amount || 1 : 1;
            const referenceDiscount = state.priceDetails ? state.priceDetails.discount || 0 : 0;
            const referenceExciseAmount = state.priceDetails ? state.priceDetails.exciseAmount || 0 : 0;
            const amount = state.updatedQuantity * state.updatedPriceDetails.rate || 0;
            state.updatedPriceDetails.discount = calculateRelativeDiscount(
              referenceAmount, referenceDiscount, amount,
            );
            state.updatedPriceDetails.exciseAmount = calculateRelativeDiscount(
              referenceAmount, referenceExciseAmount, amount,
            );
            stateUpdater(state);
          } else {
            state.shortages = state.updatedShortages;
            state.damages = state.updatedDamages;
            state.sellable = state.updatedSellable;
            state.updatedQuantity = state.sellable + state.damages + state.expiry + state.shortages;
            state.updatedPriceDetails.discount = state.updatedDiscount || 0;
            state.updatedPriceDetails.exciseAmount = state.updatedExciseAmount || 0;
            stateUpdater(state);
          }
        });
    }
    if (item === 'expiry') {
      this.checkSkuStockStatus(state, item)
        .then((res) => {
          if (res) {
            state.updatedQuantity = state.sellable + state.damages + state.shortages + state.expiry;
            const referenceAmount = state.priceDetails ? state.priceDetails.amount || 1 : 1;
            const referenceDiscount = state.priceDetails ? state.priceDetails.discount || 0 : 0;
            const referenceExciseAmount = state.priceDetails ? state.priceDetails.exciseAmount || 0 : 0;
            const amount = state.updatedQuantity * state.updatedPriceDetails.rate || 0;
            state.updatedPriceDetails.discount = calculateRelativeDiscount(
              referenceAmount, referenceDiscount, amount,
            );
            state.updatedPriceDetails.exciseAmount = calculateRelativeDiscount(
              referenceAmount, referenceExciseAmount, amount,
            );
            stateUpdater(state);
          } else {
            state.expiry = 0;
            state.updatedQuantity = state.sellable + state.damages + state.shortages + state.expiry;
            state.updatedPriceDetails.discount = state.updatedDiscount || 0;
            state.updatedPriceDetails.exciseAmount = state.updatedExciseAmount || 0;
            stateUpdater(state);
          }
        });
    }
    if (item === 'sellable') {
      this.checkSkuStockStatus(state, item)
        .then((res) => {
          if (res) {
            if (invoiceNumberStatus) {
              if (value > state.updatedSellable) {
                this.setState({ sellableValidation: true });
              } else {
                this.setState({ sellableValidation: false });
              }
            }
            state.updatedQuantity = state.sellable + state.damages + state.shortages + state.expiry;
            const referenceAmount = state.priceDetails ? state.priceDetails.amount || 1 : 1;
            const referenceDiscount = state.priceDetails ? state.priceDetails.discount || 0 : 0;
            const referenceExciseAmount = state.priceDetails ? state.priceDetails.exciseAmount || 0 : 0;
            const amount = state.updatedQuantity * state.updatedPriceDetails.rate || 0;
            state.updatedPriceDetails.discount = calculateRelativeDiscount(
              referenceAmount, referenceDiscount, amount,
            );
            state.updatedPriceDetails.exciseAmount = calculateRelativeDiscount(
              referenceAmount, referenceExciseAmount, amount,
            );
            stateUpdater(state);
          } else {
            state.shortages = state.updatedShortages;
            state.damages = state.updatedDamages;
            state.sellable = state.updatedSellable;
            state.updatedQuantity = state.sellable + state.damages + state.shortages + state.expiry;
            state.updatedPriceDetails.discount = state.updatedDiscount || 0;
            state.updatedPriceDetails.exciseAmount = state.updatedExciseAmount || 0;
            stateUpdater(state);
          }
        });
    } else {
      const referenceAmount = state.priceDetails ? state.priceDetails.amount || 1 : 1;
      const referenceDiscount = state.priceDetails ? state.priceDetails.discount || 0 : 0;
      const amount = state.updatedQuantity * state.updatedPriceDetails.rate || 0;
      state.updatedPriceDetails.discount = calculateRelativeDiscount(
        referenceAmount, referenceDiscount, amount,
      );
    }

    stateUpdater(state);
  };

  calculateLineWiseDiscount = () => {
    const { data, totalAmount } = this.state;
    const modifiedLines = data.lines.map((item) => {
      const amountDetails = getLineAmountDetails(item, totalAmount);
      const modifiedItem = {
        ...item,
        updatedPriceDetails: {
          ...item.updatedPriceDetails,
          billDiscount: amountDetails.billDiscount,
          tradeDiscount: amountDetails.tradeDiscount,
          grossAmount: amountDetails.grossAmount,
          taxAmount: amountDetails.taxAmount,
        },
      };

      return modifiedItem;
    });

    return modifiedLines;
  };

  calculateTotal = (totalAmountObj) => {
    const { data } = this.state;
    const { invoiceNumberStatus } = this.props;
    const totalAmount = { ...totalAmountObj };
    const discounts = {
      billDiscount: !invoiceNumberStatus ? totalAmount.billDiscount : 0,
      tradeDiscount: !invoiceNumberStatus ? totalAmount.tradeDiscount : 0,
    };
    if (invoiceNumberStatus) {
      data.lines.forEach((item) => {
        discounts.billDiscount += item.updatedPriceDetails.billDiscount;
        discounts.tradeDiscount += item.updatedPriceDetails.tradeDiscount;
      });

      // computation of weighted discount.
      const baseWeight = totalAmount.subTotal / data.amount.subTotal;
      discounts.billDiscount = baseWeight * data.amount.billDiscount;
      discounts.tradeDiscount = baseWeight * data.amount.tradeDiscount;
    }

    const taxableAmount = (totalAmount.subTotal
      - discounts.billDiscount - discounts.tradeDiscount) || 0;
    const vat = 0.13 * taxableAmount;
    totalAmount.billDiscount = discounts.billDiscount || 0;
    totalAmount.tradeDiscount = discounts.tradeDiscount || 0;
    totalAmount.taxAmount = vat;
    totalAmount.taxableAmount = taxableAmount;
    totalAmount.total = taxableAmount + vat;

    this.setState({ totalAmount });
  };

  calculateLineTotal = () => {
    const { data, selectedOrders, totalAmount } = this.state;
    const { invoiceNumberStatus } = this.props;
    let subTotal = 0;
    let exciseTotal = 0;

    const list = invoiceNumberStatus
      ? data.lines.filter(item => selectedOrders.indexOf(item.id) > -1) : data.lines;
    list.map(item => subTotal += item.updatedPriceDetails.netAmount);
    list.map(item => exciseTotal += item.updatedPriceDetails.exciseAmount);
    totalAmount.subTotal = subTotal;
    totalAmount.exciseAmount = exciseTotal;
    this.calculateTotal(totalAmount);
  };

  handlePrimaryCheckBoxClick = (event) => {
    const { data } = this.state;
    let selectedOrdersList = [];
    if (event.currentTarget.checked) {
      selectedOrdersList = extractSingleItemFromList(data.lines);
    }

    this.setState({ selectedOrders: selectedOrdersList }, () => this.calculateLineTotal());
  };

  handleSecondaryCheckboxClick = (data) => {
    if (data.updatedQuantity === data.updatedQuantityReference) {
      this.checkSkuStockStatus(data, 'sellable')
        .then((res) => {
          if (res) {
            this.updateCheckBox(data.id);
          }
        });
    } else {
      this.updateCheckBox(data.id);
    }
  };

  updateCheckBox = (id) => {
    const { selectedOrders } = this.state;
    const index = selectedOrders.indexOf(id);
    if (index > -1) {
      selectedOrders.splice(index, 1);
    } else {
      selectedOrders.push(id);
    }
    this.setState({ selectedOrders }, () => this.calculateLineTotal());
  };

  checkSkuStockStatus = async (order, param) => {
    const { checkSkuStockWithBatch, distributorId, displayAlert } = this.props;
    return new Promise((resolve, reject) => {
      if (!distributorId) {
        displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Distributor not selected!');
        resolve(false);
      } else {
        checkSkuStockWithBatch({
          skuId: order.skuId,
          distributorId,
          skuBatchId: order.skuBatchId,
          sellable: order.sellable,
          damages: order.damages,
          shortages: order.shortages,
          expiry: order.expiry,
          fromUI: true,
        }, {
          handleSuccess: (response) => {
            if (response.data.checkSkuStockWithBatch[qtyType[param].toLowerCase()] === true) {
              resolve(true);
            } else {
              displayAlert(ALERT_TYPE.CUSTOM_DANGER, `Sku Stock ${param === 'sellable' ? '' : qtyType[param]} Validation Error!`);
              resolve(false);
            }
          },
          handleError: (err) => {
            displayAlert(ALERT_TYPE.CUSTOM_DANGER, err);
            resolve(false);
          },
        });
      }
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
      sellableValidation: false,
      damagesValidation: false,
      shortagesValidation: false,
    });
  };

  getValidationStatus = () => {
    const lineValidation = this.linesValidationStatus();

    return (lineValidation && !Object.values(totalViewRefs)
      .find(item => (item.getValidState() === false)));
  };

  linesValidationStatus = () => {
    const { invoiceNumberStatus, displayAlert } = this.props;
    const { selectedOrders, data } = this.state;
    const status = invoiceNumberStatus ? selectedOrders.length > 0 : data.lines.length > 0;
    if (!status) {
      const message = invoiceNumberStatus
        ? ORDER_SELECTION_ERROR.NO_ORDER_SELECTED : ORDER_SELECTION_ERROR.NO_ORDER_AVAILABLE;
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, message);
    }
    return status;
  };

  exportData = () => {
    const { data, totalAmount, selectedOrders } = this.state;
    const { invoiceNumberStatus } = this.props;
    // const updatedData = clone({ lines: [...data.lines] });
    const selectedData = {};
    selectedData.lines = invoiceNumberStatus
      ? data.lines.filter(order => selectedOrders.includes(order.id))
      : [...data.lines];

    if (!invoiceNumberStatus) {
      selectedData.lines = this.calculateLineWiseDiscount();
    }
    selectedData.amount = totalAmount;
    selectedData.prnType = this.determineReturnType();

    return selectedData;
  };

  resetStates = (states = {}) => {
    this.setState({ ...states });
  };

  render() {
    const {
      data,
      dialog,
      totalAmount,
      skuBatchList,
      selectedOrders,
      enableErrorDisplay,
      sellableValidation,
      damagesValidation,
      shortagesValidation,
    } = this.state;
    const { type } = dialog;
    const {
      update,
      skuList,
      permission,
      serverResponseWaiting,
      invoiceNumberStatus,
      exciseInLine,
    } = this.props;

    const skuLines = data.lines || [];
    const priceDetails = totalAmount || {};
    const validSkuLength = skuLines.length;
    return (
      <>
        <div className="return-create">
          {type && (
            <DialogFormWrapper
              formConfig={formConfig[type]}
              dialogElement={dialog.element}
              onDialogSubmit={this.handleFormSubmit}
              onDialogCancel={this.resetDialog}
              type={type}
              disableDialogClose
              renderDialog={
                ({
                  refsObj,
                  dialogData,
                  handleInputChange,
                  enableErrorDisplay,
                  handleDropDownChange,
                }) => (
                    <>
                      {
                        (type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.CREATE) && (
                          <Form
                            show
                            type={type}
                            update={update}
                            refsObj={refsObj}
                            skuList={skuList}
                            data={dialogData}
                            permission={permission}
                            skuBatchList={skuBatchList}
                            stockTypeList={STOCK_TYPE_LIST}
                            loading={serverResponseWaiting}
                            inputCallBack={this.onInputChange}
                            handleInputChange={handleInputChange}
                            enableErrorDisplay={enableErrorDisplay}
                            dropDownCallBack={this.onDropDownChange}
                            handleDropDownChange={handleDropDownChange}
                            invoiceNumberStatus={invoiceNumberStatus}
                            sellableValidation={sellableValidation}
                            damagesValidation={damagesValidation}
                            shortagesValidation={shortagesValidation}
                            exciseInLine={exciseInLine}
                          />
                        )
                      }
                      {
                        isConfirmationType(type)
                        && Confirmation(type, 1)
                      }
                    </>
                  )
              }
            />
          )}
          <TableView
            loading={serverResponseWaiting}
            update={update}
            skuLines={skuLines}
            checkedList={selectedOrders}
            validSkuLength={validSkuLength}
            onIconClick={this.handleIconClick}
            checkBoxStatus={id => getCheckboxStatus(selectedOrders, id)}
            invoiceNumberStatus={invoiceNumberStatus}
            onPrimaryCheckBoxClick={this.handlePrimaryCheckBoxClick}
            onSecondaryCheckBoxClick={this.handleSecondaryCheckboxClick}
            exciseInLine={exciseInLine}
          />
          <TotalView
            update={update}
            refsObj={totalViewRefs}
            priceDetails={priceDetails}
            enableErrorDisplay={enableErrorDisplay}
            onInputChange={this.handleDiscountChange}
            invoiceNumberStatus={invoiceNumberStatus}
          />
        </div>
      </>

    );
  }
}

Table.propTypes = propTypes;

Table.defaultProps = defaultProps;

export default withAlert()(Table);
