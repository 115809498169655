import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import {
  breadCrumbConfig,
  crudSuccess as crudRequestConfig,
  detailsFormConfig,
  formMapper,
  summaryDetailsMapper,
  title,
  totalPriceMapper,
} from './config';
import { RETURN } from '../../../../data/enums/Route';
import Table from './table';
import SummaryDetails from './summaryDetails';
import { clone } from '../../../../utils/arrayProcessor';
import { grnImageUploader } from '../../../../utils/image';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { customerMapper } from './summaryDetails/config';
import { getPermissionForReturns } from '../../../base/permission';
import withLoading from '../../../../utils/composition/withLoading';
import { PanelCard, Button } from '../../../../v4/components';
import { getSubdUserStatusAndId, updateCustomerList } from '../../../common/HelperFunctions';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { debouncer, dropdownChange, inputChange } from '../../../../utils/formHandlers';
import { refValidator } from '../../../../utils/refGenerator';
import { ERR } from '../../orderProcessing/received/salesInvoice/config';

const propTypes = {
  getSkus: PropTypes.func.isRequired,
  createReturn: PropTypes.func.isRequired,
  updateSRN: PropTypes.func,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  approveSRN: PropTypes.func,
  getOutlets: PropTypes.func,
  getInvoiceDetail: PropTypes.func,
  history: PropTypes.instanceOf(Object).isRequired,
  getLinesByOutlet: PropTypes.func,
  getInvoiceNumberList: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  updateSRN: () => null,
  approveSRN: () => null,
  getOutlets: () => null,
  getLinesByOutlet: () => null,
  getInvoiceDetail: () => null,
  getInvoiceNumberList: () => null,
};

class createReturn extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const { id = 0, exist = false } = getSubdUserStatusAndId();
    this.distributorId = id;
    this.subDUser = exist;
    this.state = {
      skus: [],
      data: formMapper({}, this.distributorId),
      outletList: [],
      distributorList: [],
      enableErrorDisplay: false,
      invoiceNumberStatus: false,
      showDialog: false,
      loadingInvoiceStatus: false,
      distributorBatchFlag: false,
      distributorServices: {
        billing: {
          status: false,
          url: '',
          versionCode: null,
        },
      },
      distributor: {
        id: null,
        title: '',
        townList: [],
      },
      outletId: null,
      customerList: [],
      srnRouteLineList: [],
      invoiceNumberList: [],
    };

    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createReturn,
      [EVENT_OPERATION.UPDATE]: props.updateSRN,
      [EVENT_OPERATION.APPROVE]: props.approveSRN,
    };
    this.onCRUDSuccess = this.responseProcessor(this.handleSrnSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.permission = getPermissionForReturns();
  }

  componentDidMount() {
    this.getDistributorsList();
    this.getSKUs();
  }

  setDistributorServices = distributor => {
    const { distributorServices, distributor: stateDistributor } = this.state;

    stateDistributor.townList = distributor.Towns.map(town => town.id);
    stateDistributor.id = distributor.id;
    stateDistributor.title = distributor.title;
    this.setState(
      {
        distributorServices: { ...distributor.servicesUsed, id: distributor.id } || distributorServices,
        distributorBatchFlag: distributor.batchImplementation,
        distributor: stateDistributor,
      },
      () => {
        localStorage.setItem('isBillingUser', JSON.stringify(distributor.servicesUsed?.billing?.status ?? false));
        this.getOutletList();
      },
    );
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          const distributorList = response.data.distributors ? response.data.distributors.rows || [] : [];
          this.setState(
            {
              distributorList,
            },
            () => {
              if (this.subDUser && distributorList.length > 0) {
                this.setDistributorServices(distributorList[0]);
              }
            },
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getOutletList = () => {
    const { getOutlets, displayAlert } = this.props;

    const { distributor } = this.state;

    if (distributor.townList.length > 0) {
      const filter = {
        filters: [
          {
            column: 'town_id',
            value: distributor.townList.map(String),
          },
        ],
      };

      getOutlets(
        {
          filter,
        },
        {
          handleSuccess: response => {
            this.setState({ outletList: response.data.retailOutlets.rows });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus(
      { filter: {
        filters: [
          {
            column: 'active',
            value: ["true", "false"]
          },
        ],

      },
    },
      {
        handleSuccess: response => {
          this.setState({ skus: response.data.skus.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleInvoiceNumberChange = (invoiceNumber, exactlyMatchedInvoiceNumber) => {
    const { data, outletList, distributorServices } = this.state;
    const { getInvoiceDetail, displayAlert } = this.props;
    if (exactlyMatchedInvoiceNumber) {
      getInvoiceDetail(
        { invoiceNumber, distributorId: data.Distributor.id },
        {
          handleSuccess: response => {
            const responseData = {
              ...response.data.invoiceDetailsByNumber,
              uuid: Math.random().toString(36).substring(7),
            };
            const invoiceDetail =
              formMapper(responseData) ||
              formMapper(
                { uuid: Math.random().toString(36).substring(7) },
                this.userInfo.Distributor ? this.userInfo.Distributor.id : 0,
              );
            this.updateList('outletList', invoiceDetail.RetailOutlet);
            if (!invoiceDetail.Customer) invoiceDetail.Customer = customerMapper({});
            const newOrders = this.removeDuplicateOrderIds(invoiceDetail?.orders);
            this.setState({
              data: { ...invoiceDetail, orders: newOrders },
              invoiceNumberStatus: exactlyMatchedInvoiceNumber,
              outletId: responseData.RetailOutlet.id,
              customerList: updateCustomerList(
                responseData.RetailOutlet.id,
                distributorServices,
                outletList,
                exactlyMatchedInvoiceNumber,
                invoiceDetail.paymentMode,
              ),
            });
            this.getLinesByOutletId(responseData.RetailOutlet.id);
            if (invoiceDetail.totalValidOrders === 0) {
              displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Sales Return already done');
            }
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    } else if (data.orders && data.orders.length > 0) {
      data.orders = [];
      data.updatedAmount = totalPriceMapper({});
      data.amount = totalPriceMapper({});
      data.totalValidOrders = 0;
      this.setState({
        data,
        invoiceNumberStatus: exactlyMatchedInvoiceNumber,
      });
    } else {
      this.setState({
        invoiceNumberStatus: exactlyMatchedInvoiceNumber,
      });
    }
  };

  getLinesByOutletId = id => {
    const { getLinesByOutlet, displayAlert } = this.props;
    const { data } = this.state;
    getLinesByOutlet(
      { outletId: id },
      {
        handleSuccess: response => {
          const responseData = response.data.linesByOutletId;
          if (responseData.length === 1) {
            data.lineId = responseData[0] && responseData[0].id;
            this.setState({
              data,
            });
          }
          this.setState({
            srnRouteLineList: responseData || [],
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  directToMainPage = () => {
    /** direct to Srn page */
    const { history } = this.props;
    history.push(`/${RETURN}`);
  };

  onSubmit = () => {
    const valid = this.getValidationStatus();
    if (valid) {
      this.createReturn();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  getValidationStatus = () => {
    const detailsStatus = refValidator(detailsFormConfig[FORM_CONFIG.REFS_OBJ]);
    const tableStatus = this.getTableValidationStatus();

    return detailsStatus && tableStatus;
  };

  createReturn = () => {
    const { data, invoiceNumberStatus } = this.state;
    const { displayAlert } = this.props;
    const updatedData = clone(data);
    const tableData = this.getTableDetails();

    const isStockTypeNull = tableData.returnOrders.filter(order => order.stockType === null);

    if (isStockTypeNull?.length > 0) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Stock Type is required');
      return false;
    } else {
      updatedData.amount = tableData.totalAmount;
      updatedData.srnType = tableData.srnType;
      updatedData.returnOrders = tableData.returnOrders;
      updatedData.oldBillStatus = !invoiceNumberStatus;

      this.onFormSubmit(EVENT_OPERATION.CREATE, updatedData);
    }
  };

  updateList = (stateField, value) => {
    const list = this.state[stateField] || [];
    if (Array.isArray(list)) {
      list.push(value);
      this.setState({ [stateField]: list });
    }
  };

  handleSrnSuccess = (response, type) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  responseProcessor = callBack => type => response => {
    callBack(response, type);
  };

  filterItemFromList = (list, value, expectedDefaultValue, returnValueKey, key = 'id') => {
    const item = list.filter(item => item[key] === value)[0];
    const k = returnValueKey ? item[returnValueKey] || expectedDefaultValue : item;

    return k;
  };

  handleCustomerChange = customerId => {
    const { customerList, data } = this.state;
    data.Customer = this.filterItemFromList(customerList, customerId, null) || customerMapper({});
    data.customerName = data.Customer.name;
    this.setState({ data });
  };
  handleInputPaymentMode = (value, firstParam = '') => {
    this.setState(prevState => {
      const { data } = prevState;
      const updatedData = {
        ...data,
        [firstParam]: value,
      };
      if (!updatedData.RetailOutlet.id) {
        updatedData.Customer = customerMapper({});
        updatedData.customerName = '';
        updatedData.billName = '';
      }
      return { data: updatedData };
    });
  };

  getInvoiceNumberList = salesInvoiceNumber => {
    const { data, invoiceNumberList } = this.state;
    const { getInvoiceNumberList, displayAlert } = this.props;
    if (!data.Distributor.id) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Distributor is not selected');
      return 0;
    }
    if (salesInvoiceNumber.length >= 3) {
      let exactlyMatched = invoiceNumberList.indexOf(salesInvoiceNumber) > -1;
      if (!exactlyMatched) {
        this.setState({ loadingInvoiceStatus: true });
        getInvoiceNumberList(
          {
            invoiceString: salesInvoiceNumber,
            distributorId: data.Distributor.id,
          },
          {
            handleSuccess: response => {
              exactlyMatched = response.data.searchInvoiceNumber.matched;
              this.setState(
                {
                  invoiceNumberList: response.data.searchInvoiceNumber.invoiceNumbers || [],
                  loadingInvoiceStatus: false,
                },
                () => this.handleInvoiceNumberChange(salesInvoiceNumber, exactlyMatched),
              );
            },
            handleError: error => {
              this.setState({ invoiceNumberList: [], loadingInvoiceStatus: false }, () =>
                this.handleInvoiceNumberChange(salesInvoiceNumber, exactlyMatched),
              );
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      } else {
        this.handleInvoiceNumberChange(salesInvoiceNumber, exactlyMatched);
      }
    } else {
      this.setState({ invoiceNumberList: [], loadingInvoiceStatus: false }, () =>
        this.handleInvoiceNumberChange(salesInvoiceNumber, false),
      );
    }
  };

  resetData = (updatedData, callBack) => {
    const { outletId, distributorServices, outletList, invoiceNumberStatus, data, reset } = this.state;
    const refreshedData = invoiceNumberStatus
      ? summaryDetailsMapper({
          invoiceNumber: updatedData.salesInvoiceNumber,
          Distributor: { id: updatedData.Distributor.id },
        })
      : summaryDetailsMapper({
          ...updatedData,
          invoiceNumber: updatedData.salesInvoiceNumber,
          Distributor: { id: updatedData.Distributor.id },
        });
    this.setState({ data: formMapper(refreshedData), customerList: [] }, () => {
      debouncer(callBack, 300)(updatedData.salesInvoiceNumber);
      if (outletId !== null) {
        this.setState({
          customerList: updateCustomerList(outletId, distributorServices, outletList, invoiceNumberStatus),
        });
      }
    });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedData = inputChange(data, event, firstParam, paramList);
    switch (event.target.name) {
      case 'salesInvoiceNumber':
        this.resetData(updatedData, this.getInvoiceNumberList);
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  getCustomerList = (outletId, distributorId) => {
    const { getCustomers } = this.props;
    getCustomers(
      {
        offset: 0,
        filter: {
          filters: [
            {
              column: 'retail_outlet_id',
              value: [outletId?.toString()],
            },
            {
              column: 'active',
              value: ['true'],
            },
            {
              column: 'distributor_id',
              value: [distributorId?.toString()],
            },
          ],
        },
        retailOutletId: outletId,
        distributorId: distributorId,
      },
      {
        handleSuccess: response => {
          this.setState({
            customerList: response?.data?.customers?.rows || [],
          });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleDropDownChange = (value, parameterRef = [], name, selectedObj = {}) => {
    const { data, distributorServices, outletList, invoiceNumberStatus } = this.state;

    const updatedData = dropdownChange(data, parameterRef, value);
    switch (name) {
      case 'outlet':
        updatedData.Customer = customerMapper({});
        this.setState(
          {
            data: updatedData,
            outletFlag: true,
          },
          () => {
            this.getLinesByOutletId(updatedData.RetailOutlet.id);
            this.getCustomerList(updatedData.RetailOutlet.id, updatedData.Distributor.id);
          },
        );

        break;
      case 'customer':
        this.handleCustomerChange(updatedData.Customer.id);
        break;
      case 'distributor':
        updatedData.Customer = customerMapper({});
        this.setState({ data: updatedData }, () => this.setDistributorServices(selectedObj));
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  toggleDialogAppearance = e => {
    const { showDialog } = this.state;
    e.preventDefault();
    this.setState({ showDialog: !showDialog });
  };

  handleDialogSubmit = ledger => {
    const { data } = this.state;
    const { displayAlert } = this.props;
    const { customerList } = this.state;
    const formattedLedger = customerMapper(ledger) || {};
    if (formattedLedger.id) {
      data.Customer = formattedLedger;
      data.customerName = formattedLedger.name;
      customerList.push(formattedLedger);
      this.setState({ customerList, showDialog: false, data });
    } else {
      this.setState({ showDialog: false });
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Customer Id not available');
    }
  };
  removeDuplicateOrderIds = list => {
    const uniqueLines = {};
    list?.forEach?.(item => {
      const uniqueItemLines = [];
      item?.Lines?.forEach?.(line => {
        if (!uniqueLines[line?.id]) {
          uniqueLines[line?.id] = true;
          uniqueItemLines.push(line);
        }
      });
      item.Lines = uniqueItemLines;
    });
    return list;
  };

  render() {
    const {
      data,
      skus,
      outletList,
      editable,
      approvable,
      distributor,
      distributorList,
      distributorServices,
      enableErrorDisplay,
      invoiceNumberStatus,
      outletId,
      customerList,
      srnRouteLineList,
      invoiceNumberList,
      showDialog,
      loadingInvoiceStatus,
      distributorBatchFlag,
    } = this.state;
    const { displayAlert, serverResponseWaiting, getSKUBatchDetail } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <div className="prn-page-header">
              <PageHeader breadCrumb={breadCrumbConfig} title={title} />
              <div className="flex m-0">
                <Button
                  secondary
                  small
                  disabled={serverResponseWaiting}
                  onClick={() => {
                    this.setState({ data: formMapper({}, this.distributorId) });
                  }}
                >
                  <span> Cancel</span>
                </Button>
                <Button
                  small
                  primary
                  disabled={serverResponseWaiting}
                  onClick={() => this.onSubmit()}
                  onKeyDown={e => {
                    (e.key === 'Enter' || e.code === 'Space') && e.preventDefault();
                  }}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <PanelCard cardTitle="details">
            <SummaryDetails
              enableErrorDisplay={enableErrorDisplay}
              loading={serverResponseWaiting}
              outletList={outletList}
              distributorList={distributorList}
              formConfig={detailsFormConfig}
              invoiceNumberStatus={invoiceNumberStatus}
              data={data}
              distributor={distributor}
              subDUser={this.subDUser}
              distributorServices={distributorServices}
              outletId={outletId}
              customerList={customerList}
              srnRouteLineList={srnRouteLineList}
              handleCustomerChange={this.handleCustomerChange}
              handleInputChange={this.handleInputChange}
              handleDropDownChange={this.handleDropDownChange}
              invoiceNumberList={invoiceNumberList}
              handleIconClick={this.toggleDialogAppearance}
              handleDialogSubmit={this.handleDialogSubmit}
              showDialog={showDialog}
              loadingInvoiceStatus={loadingInvoiceStatus}
              handleInputPaymentMode={this.handleInputPaymentMode}
            />
          </PanelCard>
          <PanelCard cardTitle="sku" skuClassStatus>
            <Table
              data={data}
              skuList={skus}
              distributorId={distributor.id}
              imageUploader={grnImageUploader}
              displayAlert={displayAlert}
              enableErrorDisplay={enableErrorDisplay}
              invoiceNumberStatus={invoiceNumberStatus}
              getStatus={childMethod => (this.getTableValidationStatus = childMethod)}
              getDetails={childMethod => (this.getTableDetails = childMethod)}
              getSKUBatchDetail={getSKUBatchDetail}
              distributorList={distributorList}
              distributorBatchFlag={distributorBatchFlag}
            />
          </PanelCard>
        </div>
      </Fragment>
    );
  }
}

createReturn.propTypes = propTypes;

createReturn.defaultProps = defaultProps;

export default withLoading(withAlert()(createReturn));
