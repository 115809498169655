import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, TabList, TabPanel, Tabs
} from '../../../../v4/components';
import withErrorAndLoading from '../../../../utils/composition/withLoading';
import {
  BreadCrumb, Icon ,Button
} from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { breadCrumbConfig } from './config';
import OutletDetailWrap from '../../../salesForce/route/detail/OutletDetailStyled';
import General from './General';
import Permission from './Permission';
import HolidaysSection from '../../../components/HolidaysSection';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    active: PropTypes.bool,
    address: PropTypes.string,
    Town: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    servicesUsed: PropTypes.shape({
      billing: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
      delivery: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
      logistic: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
    }),
  }).isRequired,
  menu: PropTypes.shape({
    townList: PropTypes.instanceOf(Array),
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.shape(Object).isRequired,
  crudMode: PropTypes.string.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  handleButtonCancel: PropTypes.func.isRequired,
  breadCrumb: PropTypes.instanceOf(Array).isRequired,
  getLatLng: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  srnService: PropTypes.bool.isRequired,
  vendorMapSection: PropTypes.bool.isRequired,
  srnChanged: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object),
  params: PropTypes.shape(Object).isRequired,
  callHolidaySection: PropTypes.bool.isRequired,
  handleSkuSync: PropTypes.func.isRequired,
  marketPlace: PropTypes.bool,
  marketChanged: PropTypes.func.isRequired,
  getHeader: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onUploadClick: PropTypes.func.isRequired,
  handleDialogOkClick: PropTypes.func.isRequired,
  handleDialogCancelClick: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(Object).isRequired,
  skuSync: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  enableErrorDisplay: false,
  marketPlace: false,
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const {
    menu,
    data,
    refsObj,
    crudMode,
    onInputChange,
    onDropDownChange,
    enableErrorDisplay,
    handleButtonSubmit,
    handleButtonCancel,
    handleEditIconClick,
    getLatLng,
    loading,
    srnChanged,
    srnService,
    marketPlace,
    marketChanged,
    vendors,
    permission,
    vendorMapSection,
    params,
    callHolidaySection,
    getHeader,
    labels,
    handleSkuSync,
    handleDialogOkClick,
    handleDialogCancelClick,
    onDownloadClick,
    onUploadClick,
    skuSync,
  } = props;

  const showEditButton = crudMode !== EVENT_OPERATION.CREATE;
  const disableEditButton = crudMode === EVENT_OPERATION.UPDATE;
  const header = getHeader();
  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled className='padding-0'>
          <BreadCrumb list={breadCrumbConfig} />
          <PanelHeader>
            <h2>{header}</h2>
            <div className='flex m-0'>
              {
                // eslint-disable-next-line no-mixed-operators
                crudMode !== EVENT_OPERATION.READ && (
                  <div>
                    <Button small disabled={loading} secondary onClick={() => handleButtonCancel()}>
                      <span>Cancel</span>
                    </Button>
                    <Button primary disabled={loading} small onClick={() => handleButtonSubmit()}>
                      <span>Save</span>
                    </Button>
                  </div>
                )
              }
              {!disableEditButton
                ? showEditButton && (
                  <Button
                    secondary
                    iconBtnSmall
                    disabled={disableEditButton}
                    onClick={handleEditIconClick}
                    className='ml-16'
                  >
                    <Icon iconName='pencil' />
                  </Button>
                )
                : ''}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content custom-height section-tab'>
        {showEditButton ? (
          <OutletDetailWrap>
            <Tabs>
              <TabList>
                <Tab>
                  <span className='tab-label'>Details</span>
                </Tab>
                {permission.update && (
                  <Tab>
                    <span className='tab-label'>Permissions</span>
                  </Tab>
                )}
                <Tab>
                  <span className='tab-label'>Calendar</span>
                </Tab>
              </TabList>
              <div className='route-tab-content'>
                <TabPanel>
                  <section id='detail-tab'>
                    <General
                      data={data}
                      menu={menu}
                      labels={labels}
                      crudMode={crudMode}
                      refsObj={refsObj}
                      loading={loading}
                      onInputChange={onInputChange}
                      enableErrorDisplay={enableErrorDisplay}
                      onDropDownChange={onDropDownChange}
                      getLatLng={getLatLng}
                      handleButtonSubmit={handleButtonSubmit}
                      handleButtonCancel={handleButtonCancel}
                      showEditButton={showEditButton}
                      disableEditButton={disableEditButton}
                      handleEditIconClick={handleEditIconClick}
                    />
                  </section>
                </TabPanel>
                {permission.update && (
                  <TabPanel>
                    <section id='permission-tab'>
                      <Permission
                        data={data}
                        servicesUsed={data.servicesUsed}
                        appPermissions={data.appPermissions}
                        marketPlacePermissions={data.marketPlacePermissions}
                        srn={data.salesReturnPolicy} 
                        readyStock={data.readyStock}
                        batchImplementation={data.batchImplementation}
                        enableManualGRN={data.enableManualGRN}
                        enableDnEApproval={data.enableDnEApproval}
                        zeroDayInventory={data.zeroDayInventory}
                        outletAutoApproval={data.outletAutoApproval}
                        onInputChange={onInputChange}
                        enableErrorDisplay={enableErrorDisplay}
                        refsObj={refsObj}
                        srnChanged={srnChanged}
                        marketChanged={marketChanged}
                        srnService={srnService}
                        marketPlace={marketPlace}
                        crudMode={crudMode}
                        handleButtonSubmit={handleButtonSubmit}
                        vendors={vendors}
                        vendorId={data.vendorLedgerId}
                        vendorIdv2={data.vendorLedgerIdv2}
                        onDropDownChange={onDropDownChange}
                        handleEditIconClick={handleEditIconClick}
                        vendorMapSection={vendorMapSection}
                        handleSkuSync={handleSkuSync}
                        handleDialogOkClick={handleDialogOkClick}
                        handleDialogCancelClick={handleDialogCancelClick}
                        skuSync={skuSync}
                        onDownloadClick={onDownloadClick}
                        onUploadClick={onUploadClick}
                      />
                    </section>
                  </TabPanel>
                )}
                <TabPanel>
                  <section id='calendar-tab' className={crudMode === EVENT_OPERATION.READ && 'disabled'}>
                    <HolidaysSection params={params} callHolidaySection={callHolidaySection} replicateHoliday />
                  </section>
                </TabPanel>
              </div>
            </Tabs>
          </OutletDetailWrap>
        ) : (
          <General
            data={data}
            menu={menu}
            labels={labels}
            crudMode={crudMode}
            refsObj={refsObj}
            loading={loading}
            onInputChange={onInputChange}
            enableErrorDisplay={enableErrorDisplay}
            onDropDownChange={onDropDownChange}
            getLatLng={getLatLng}
            handleButtonSubmit={handleButtonSubmit}
            handleButtonCancel={handleButtonCancel}
            showEditButton={showEditButton}
            disableEditButton={disableEditButton}
            handleEditIconClick={handleEditIconClick}
          />
        )}
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(View);

export default GeneralInfo;
