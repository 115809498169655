import { Icon } from '..';
import { DEFAULT_PAGINATION_OPTION, DEFAULT_SELECT_OPTION, PAGE_TO_DISPLAY, SELECT_OPTIONS } from '../../constants/pagination';
import PaginationStyled from './PaginationStyled';
import SelectPage from './SelectPage';

interface PageProps {
  limit: number;
  offset: number;
  page: number;
}

interface PaginationProps {
  currentPage: number;
  limit: number;
  totalItems: number;
  orientation?: string;
  onPageChange: (pageDetails: PageProps) => void;
}

const Pagination = ({ currentPage, onPageChange, limit, totalItems, orientation, ...props }: PaginationProps) => {
  const totalPage = Math.ceil(totalItems / limit);

  const handlePageLimitChange = (value: any) => {
    onPageChange({
      limit: value,
      offset: totalItems > value ? (currentPage - 1) * value : (DEFAULT_PAGINATION_OPTION.page - 1) * value,
      page: totalItems > value ? currentPage : DEFAULT_PAGINATION_OPTION.page,
    });
  };

  // tslint:disable-next-line: no-shadowed-variable
  const checkIfLowerLimitReached = (currentPage: number) => currentPage <= PAGE_TO_DISPLAY;

  // tslint:disable-next-line: no-shadowed-variable
  const checkIfUpperLimitReached = (currentPage: number) => currentPage + PAGE_TO_DISPLAY >= totalPage;

  /**
   * @param isLowerLimitReached : if lower limit is reached
   * @param isUpperLimitReached: if upper limit is reached
   * @param totalNumberOfPages: total Number of pages
   * @param currentPage: Current pages
   * @return {number[]} list of the pages to be rendered
   */
  const getPageList = (
    isLowerLimitReached: boolean,
    isUpperLimitReached: boolean,
    totalNumberOfPages: number,
    // tslint:disable-next-line: no-shadowed-variable
    currentPage: number,
  ) => {
    const startIndex = isLowerLimitReached ? 1 : currentPage - PAGE_TO_DISPLAY;
    const endIndex = isUpperLimitReached ? totalNumberOfPages : currentPage + PAGE_TO_DISPLAY;
    return Array(endIndex - startIndex + 1)
      .fill(0)
      .map((e, i) => i + startIndex);
  };

  const handlePageNumberChange = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      onPageChange({
        limit,
        offset: (pageNumber - 1) * limit,
        page: pageNumber,
      });
    }
  };

  const handleButtonClick = (type: string) => {
    switch (type) {
      case 'next':
        if (currentPage < totalPage) {
          onPageChange({
            limit,
            offset: currentPage * limit,
            page: currentPage + 1,
          });
        }
        break;
      case 'prev':
        if (currentPage > 1) {
          onPageChange({
            limit,
            offset: (currentPage - 2) * limit,
            page: currentPage - 1,
          });
        }
        break;
      case 'first':
        onPageChange({
          limit,
          offset: 0,
          page: 1,
        });
        break;
      case 'last':
        onPageChange({
          limit,
          offset: (totalPage - 1) * limit,
          page: totalPage,
        });
        break;
      default:
        break;
    }
  };

  const lowerLimitReached = checkIfLowerLimitReached(currentPage);
  const upperLimitReached = checkIfUpperLimitReached(currentPage);

  const pageList = getPageList(lowerLimitReached, upperLimitReached, totalPage, currentPage);
  const pageStartIndex = (currentPage - 1) * limit + 1;
  const pageEndIndex = pageStartIndex + limit - 1;

  return (
    <>
      <PaginationStyled>
        <div className="pagination-wrapper">
          {totalItems > SELECT_OPTIONS[0].value - 1 && (
            <SelectPage
              list={SELECT_OPTIONS}
              name="pagination-select"
              defaultValue={DEFAULT_SELECT_OPTION}
              orientation={orientation}
              onChange={handlePageLimitChange}
              showTotal={totalItems > DEFAULT_SELECT_OPTION}
              limit={limit}
            />
          )}
          {totalItems > 0 && (
            <div className="pagination-control">
              <div className="pagination-control-inner">
                <div className="info-display">
                  <span className="pagination-display-label">
                    Showing {pageStartIndex.toLocaleString()} to{' '}
                    {pageEndIndex < totalItems ? pageEndIndex.toLocaleString() : totalItems.toLocaleString()} of{' '}
                    {totalItems.toLocaleString()}
                  </span>
                </div>
                <div className="nav">
                  <span style={{ marginRight: 16 }} onClick={() => handleButtonClick('first')}>
                    <Icon iconName="angle-left" />
                  </span>
                  <span style={{ marginRight: 16 }} onClick={() => handleButtonClick('prev')}>
                    <Icon iconName="caret-left" />
                  </span>
                  {!lowerLimitReached && <span className="dot">...</span>}
                  <div className="pagination-item">
                    {pageList.map(pageIndex => (
                      <span
                        key={`pagination-pageIndex-${pageIndex}`}
                        className={pageIndex === currentPage ? 'currentPage active' : 'page'}
                        onClick={() => handlePageNumberChange(pageIndex)}
                      >
                        {pageIndex}
                      </span>
                    ))}
                    {!upperLimitReached && <span className="dot">...</span>}
                  </div>
                  <span style={{ marginLeft: 16 }} onClick={() => handleButtonClick('next')}>
                    <Icon iconName="caret-right" />
                  </span>
                  <span style={{ marginLeft: 16 }} onClick={() => handleButtonClick('last')}>
                    <Icon iconName="angle-right" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </PaginationStyled>
    </>
  );
};

export default Pagination;
