import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { Icon, tableData } from '../../../../v4/components';
import { normalPresentor } from '../../../../utils/date';
import { getUpdatedConfigForTargetDimension } from '../../distributorSalesTarget/config';

// todo update the configurations.
const labelMappings = {
  title: 'title',
  startDate: 'startDate',
  endDate: 'endDate',
  target: 'target',
  icon: 'icon',
};

const returnTableBody = (data, onIconClick, handleBrandDialog, permission) => {
  const table = {};
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data.title}</td>;
  table[labelMappings.startDate] = <td key={`${data.id}-startDate`}>{normalPresentor(data.startDate)}</td>;
  table[labelMappings.endDate] = <td key={`${data.id}-endDate`}>{normalPresentor(data.endDate)}</td>;
  table[labelMappings.target] = (
    <td key={`${data.id}-target`} className='right-align'>
      {data.target.toLocaleString()}
    </td>
  );
  table[labelMappings.icon] = (
    <td key={`${data.id}-icon`} className='right-align' onClick={(e) => e.stopPropagation()}>
      <>
        {permission.read && <span
          className='view'
          onClick={() => onIconClick(EVENT_OPERATION.READ, {}, handleBrandDialog(EVENT_OPERATION.READ, data))}
        >
          <Icon iconName='view' />
          View
        </span>}
        {permission.update && <span onClick={() => onIconClick(EVENT_OPERATION.UPDATE, {}, handleBrandDialog(EVENT_OPERATION.UPDATE, data))}>
          <Icon iconName='pencil' />
          Edit
        </span>}
      </>
    </td>
  );
  return table;
};
const labelConfig = [
  {
    id: 1,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.startDate,
    title: 'Start Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.endDate,
    title: 'End Date',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.target,
    title: `Targets`,
    show: true,
    classNames: 'text-captalize'
  },
  {
    id: 5,
    label: labelMappings.icon,
    title: '',
    show: true,
  },
];

const TableBody = ({ data, onTableBodyClick, onIconClick, handleBrandDialog, permission }) => {
  const tableBody = returnTableBody(data, onIconClick, handleBrandDialog, permission);
  return (
    <tr key={data.id} className='cursor-pointer' onClick={() => onTableBodyClick(data)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};


const TableHeader = ({ targetDimensionMetric }) => {
  const updatedLabelConfig = getUpdatedConfigForTargetDimension(labelConfig, labelMappings, targetDimensionMetric);
  return (
    <thead>
      <tr>{updatedLabelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
    </thead>
  )
};

TableBody.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  onIconClick: PropTypes.func.isRequired,
  onTableBodyClick: PropTypes.func.isRequired,
  handleBrandDialog: PropTypes.func.isRequired,
};

export { TableHeader, TableBody };
