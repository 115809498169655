// USE the bread crumb function over here.
const login = {
  id: 0,
  title: 'URL1',
  path: '/login',
  active: false,
};

const test = {
  id: 1,
  path: 'URL2',
  url: '/login',
  active: false,
};

const details = {
  id: 2,
  title: 'Details',
  path: '#',
  active: true,
};

const CONFIGURATION_BASE = 'configuration';
const INVENTORY_BASE = 'inventory';
const SALES_BASE = 'sales';
const REPORTS_BASE = 'reports';
const SALES_FORCE_BASE = 'sales force';
const ERR_BASE = 'error';
const DASHBOARD_BASE = 'dashboard';
const COLLECTION_BASE = 'collections';
const USER_PROFILE = 'user profile';
const PUSH_MESSAGE_BASE = 'push message';
const SUPPORT_BASE = 'support';
const REQUESTS_BASE = 'requests';
const TAS = 'tas';
const CALL_PLAN_BASE = 'Call Plan';
const PRODUCT_CATALOG = 'Product Catalog';
const SKU_GROUP_TITLE = 'SKU Group';
const ICO_GROUP_TITLE = 'ICO Group';
const SECURITY = 'security';

const configuration = {
  id: 0,
  title: CONFIGURATION_BASE,
  path: '#',
  active: false,
};
const inventory = {
  id: 0,
  title: INVENTORY_BASE,
  path: '#',
  active: false,
};
const sales = {
  id: 0,
  title: SALES_BASE,
  path: '#',
  active: false,
};

const salesForce = {
  id: 0,
  title: SALES_FORCE_BASE,
  path: '#',
  active: false,
};

const reports = {
  id: 0,
  title: REPORTS_BASE,
  path: '#',
  active: false,
};

const dashboard = {
  id: 0,
  title: DASHBOARD_BASE,
  path: '/dashboard',
  active: false,
};

const collection = {
  id: 1,
  title: COLLECTION_BASE,
  path: '#',
  active: false,
};
const userprofile = {
  id: 0,
  title: USER_PROFILE,
  path: '#',
  active: false,
};

const support = {
  id: 0,
  title: SUPPORT_BASE,
  path: '#',
  active: false,
};
const requests = {
  id: 0,
  title: REQUESTS_BASE,
  path: '#',
  active: false,
};

const callPlanBase = {
  id: 0,
  title: CALL_PLAN_BASE,
  path: '#',
  active: false,
};


const salesForceUrl = SALES_FORCE_BASE.replace(' ', '-');
const userProfileUrl = USER_PROFILE.replace(' ', '-');
const pushMessageUrl = PUSH_MESSAGE_BASE.replace(' ', '-');

const ARC = `${CONFIGURATION_BASE}/arc`;
const BU = `${CONFIGURATION_BASE}/bu`;
const CALL_ROSTER = `${salesForceUrl}/call-roster`;
const SALES_FORCE_ROSTER = `${salesForceUrl}/sales-force-roster`;
const CATALOG = `${CONFIGURATION_BASE}/catalog`;
const CATALOG_DETAIL_LIST = `${CATALOG}/details`;
const SKU_GROUP = `${CONFIGURATION_BASE}/sku-group`;
const PERFECT_CALL = `${CONFIGURATION_BASE}/perfect-call`;
const ICO_GROUP = `${CONFIGURATION_BASE}/ico-group`;
const CHANNEL = `${CONFIGURATION_BASE}/channel`;
const CHANNEL_DETAILS = `${CHANNEL}/details`;
const DASHBOARD = 'dashboard';
const DATA_MASK = 'data-mask';
const DSR = `${REPORTS_BASE}/dsr`;
const PSR = `${REPORTS_BASE}/psr`;
const NSR = `${REPORTS_BASE}/net-sales-report`;
const DISTRIBUTION_COVERAGE = `${REPORTS_BASE}/distribution-coverage`;
const PRODUCTIVITY = `${REPORTS_BASE}/productivity`;
const TAS_PRODUCTIVITY = `${REPORTS_BASE}/tas-productivity`;
const USER_CLUSTER = `${REPORTS_BASE}/user-cluster`;
const FOCUSED_SKU = `${CONFIGURATION_BASE}/focusedSku`;
const FOCUSED_SKU_DETAILS = `${FOCUSED_SKU}/details`;
const GRN = `${INVENTORY_BASE}/grn`;
const GRN_CREATE = `${GRN}/create`;
const GRN_DETAILS = `${GRN}/details`;
const LEAVE_MANAGEMENT = `${salesForceUrl}/leave-management`;
const MERCHANDISE = `${salesForceUrl}/merchandise-device`;
const MERCHANDISE_DETAILS = `${MERCHANDISE}/details`;
const MERCHANDISE_CREATE = `${MERCHANDISE}/create`;
const OPENING_BALANCE = `${INVENTORY_BASE}/opening-balance`;
const ORDER_FULFILMENT = `${SALES_BASE}/order-fulfilment`;
const ORDER_FULFILMENT_CREATE = `${ORDER_FULFILMENT}/create`;
const ORDER_INVOICE = `${ORDER_FULFILMENT}/invoice`;
const OUTLET = `${SALES_BASE}/outlet`;
const OUTLET_CREATE = `${OUTLET}/create`;
const OUTLET_DETAILS = `${OUTLET}/details`;
const PRN = `${INVENTORY_BASE}/prn`;
const PRN_CREATE = `${PRN}/create`;
const PRN_DETAILS = `${PRN}/details`;
const PROMOTION = `${salesForceUrl}/promotion`;
const PROMOTION_DETAILS = `${PROMOTION}/details`;
const ROUTE = `${salesForceUrl}/route`;
const ROUTE_CREATE = `${ROUTE}/create`;
const ROUTE_DETAILS = `${ROUTE}/details`;
const ROUTE_SEGMENTATION = `${salesForceUrl}/route-segmentation`;
const ROUTE_SEGMENTATION_DETAILS = `${ROUTE_SEGMENTATION}/details`;
const SBD = `${CONFIGURATION_BASE}/sbd`;
const SBD_DETAILS = `${SBD}/details`;
const SKU = `${CONFIGURATION_BASE}/sku`;
const SKU_CREATE = `${SKU}/create`;
const SKU_DETAILS = `${SKU}/details`;
const SRN = `${SALES_BASE}/srn`;
const SRN_PENDING = `${SALES_BASE}/srn-pending/details`;
const SRN_CREATE = `${SRN}/create`;
const SRN_DETAILS = `${SRN}/details`;
const RETURN = `${SALES_BASE}/returns`;
const RETURN_CREATE = `${RETURN}/create`;
const RETURN_RECONCILED = `${SALES_BASE}/reconcile/details`;
const RETURN_PENDING = `${SALES_BASE}/pending/details`;
const STOCK_ADJUSTMENT = `${INVENTORY_BASE}/stock-adjustment`;
const STOCK_LEDGER = `${INVENTORY_BASE}/stock-ledger`;
const SUB_D = `${CONFIGURATION_BASE}/sub-d`;
const SUB_D_ASSORTMENT = `${CONFIGURATION_BASE}/subd-assortment`;
const SUB_D_ASSORTMENT_DETAILS = `${SUB_D_ASSORTMENT}/details`;
const SUB_D_CREATE = `${SUB_D}/create`;
const SUB_D_DETAILS = `${SUB_D}/details`;
const TARGET_ACHIEVEMENT = `${salesForceUrl}/target-achievement`;
const TARGET_ACHIEVEMENT_DETAILS = `${TARGET_ACHIEVEMENT}/details`;
const TARGET_COVERAGE = `${salesForceUrl}/target-coverage`;
const TARGET_COVERAGE_DETAILS = `${TARGET_COVERAGE}/details`;
const TARGET_BRAND_COVERAGE = `${salesForceUrl}/target-brand-coverage`;
const TARGET_BRAND_COVERAGE_DETAILS = `${TARGET_BRAND_COVERAGE}/details`;
const TARGET_TOOLS = `${salesForceUrl}/cluster-target-tools`;
const TERRITORY = `${CONFIGURATION_BASE}/territory`;
const TOWN = `${CONFIGURATION_BASE}/town`;
const USER = `${CONFIGURATION_BASE}/user`;
const USER_ACCOUNT = `${USER}/user-account`;
const USER_CREATE = `${USER}/create`;
const USER_ROLE = `${USER}/user-role`;
const USER_ROLE_DETAILS = `${USER}/user-role/details`;
const USER_ROLE_CREATE = `${USER}/user-role/create`;
const DESIGNATION = `${CONFIGURATION_BASE}/designation`;
const CHANNEL_ASSORTMENT = `${CONFIGURATION_BASE}/channel-assortment`;
const CHANNEL_ASSORTMENT_DETAILS = `${CHANNEL_ASSORTMENT}/details`;
const REPLENISHMENT = `${SUB_D}/replenishment`;
const REPLENISHMENT_DETAILS = `${REPLENISHMENT}/details`;
const SUBD_STOCK_AVAILABILITY = `${REPORTS_BASE}/subd-stock-availability`;
const SUBD_REPLENISHMENT_REPORT = `${salesForceUrl}/subd-replenishment`;
const STOCK_REPLACEMENT_REPORT = `${REPORTS_BASE}/stock-replacement`;
const ASM_REPORT = `${REPORTS_BASE}/asm`;
const STL_REPORT = `${REPORTS_BASE}/stl`;
const DSE_REPORT = `${REPORTS_BASE}/dse`;
const TAS_REPORT = `${REPORTS_BASE}/tas`;
const ORDER_ANALYSIS_REPORT = `${REPORTS_BASE}/order-analysis-report`;
const DSE_DETAIL = `${DASHBOARD}/dse-detail`;
const SKU_COVERAGE = `${REPORTS_BASE}/sku-coverage`;
const SKU_DISTRIBUTION = `${REPORTS_BASE}/sku-coverage-distribution`;
const SKU_DISTRIBUTION_DETAILS = `${SKU_DISTRIBUTION}/:id/details`;
const BRAND_COVERAGE = `${REPORTS_BASE}/brand-coverage`;
const CALLAGE = `${REPORTS_BASE}/callage`;
const TARGET_ACHIEVEMENT_REPORT = `${REPORTS_BASE}/target-achievement`;
const RECEIPTS = `${SALES_BASE}/${COLLECTION_BASE}/receipts`;
const CHEQUES = `${SALES_BASE}/${COLLECTION_BASE}/cheques`;
const CASH = `${SALES_BASE}/${COLLECTION_BASE}/cash`;
const POST_DATED_CHEQUES = `${SALES_BASE}/${COLLECTION_BASE}/post-dated-cheques`;
const PFD_SALES_TARGET = `${REPORTS_BASE}/pfd-sales-target`;
const PRODUCTIVE_COVERAGE = `${REPORTS_BASE}/productive-coverage`;
const PRODUCTIVE_COVERAGE_DETAIL = `${PRODUCTIVE_COVERAGE}/details`;
const BRAND_DISTRIBUTION = `${REPORTS_BASE}/brand-coverage-distribution`;
const BRAND_DISTRIBUTION_DETAIL = `${BRAND_DISTRIBUTION}/details`;
const CLASSIFICATION = `${CONFIGURATION_BASE}/classification`;
const OUTSTANDINGS = `${SALES_BASE}/${COLLECTION_BASE}/outstandings`;
const ORDER_SUMMARY_REPORT = `${REPORTS_BASE}/order-summary-report`;

const PROFILE = `${userProfileUrl}`;
const PUSH_MESSAGE = `${salesForceUrl}/${pushMessageUrl}`;
const PUSH_MESSAGE_CREATE = `${PUSH_MESSAGE}/create`;
const PUSH_MESSAGE_DETAILS = `${PUSH_MESSAGE}/details`;
const WAREHOUSE_LEVELS = `${INVENTORY_BASE}/warehouse-levels`;
const WAREHOUSE = `${INVENTORY_BASE}/warehouse`;
const WAREHOUSE_REPORT = `${REPORTS_BASE}/warehouse-report`;
const WAREHOUSE_INVENTORY = `${INVENTORY_BASE}/warehouse-inventory`;
const CATEGORY_CHANGE_REQUEST = `${SUPPORT_BASE}/requests/category-change-request`;
const ROUTE_TRANSFER = `${SUPPORT_BASE}/requests/route-transfer`;
const GEO_LOCATION = `${SUPPORT_BASE}/${REQUESTS_BASE}/geo-location`;
const DE_BOARD_OUTLET = `${SUPPORT_BASE}/${REQUESTS_BASE}/de-board-outlet`;
const TIME_SPEND_ON_ROUTE = `${REPORTS_BASE}/time-on-route`;
const ATTENDANCE = `${salesForceUrl}/attendance`;
const MERCHANDISE_REPORT = `${REPORTS_BASE}/merchandise-report`;
const LOCATION_HIERARCHY = `${CONFIGURATION_BASE}/location-hierarchy`;
const LOCATION_HIERARCHY_DETAILS = `${CONFIGURATION_BASE}/location-hierarchy-details`;
const SYSTEM_ALERT = `${CONFIGURATION_BASE}/system-alert`;
const SYSTEM_ALERT_CREATE = `${SYSTEM_ALERT}/create`;
const SYSTEM_ALERT_DETAILS = `${SYSTEM_ALERT}/details`;
const CAMPAIGNS = `${CONFIGURATION_BASE}/campaigns`;
const CAMPAIGNS_DETAILS = `${CAMPAIGNS}/details`;
const SUB_CAMPAIGN = `${CONFIGURATION_BASE}/campaigns/sub-campaign`;
const GRN_REPORT = `${REPORTS_BASE}/grn-report`;
const PRESENTATION = `${CONFIGURATION_BASE}/presentation`;
const ICO = `${INVENTORY_BASE}/ico`;
const DISTRIBUTOR_SALES_TARGET = `${salesForceUrl}/distributor-sales-target`;
const DISTRIBUTOR_SALES_TARGET_DETAILS = `${DISTRIBUTOR_SALES_TARGET}/details`;
const CALL_PLAN = `${TAS}/callplan`;
const TAS_OUTLET = `${TAS}/outlet`;
const TAS_DASHBOARD = 'tas-dashboard';
const TAS_USERDETAIL_DASHBOARD = `${TAS}/user-detail`;
const TAS_CALLAGE = `${REPORTS_BASE}/tas-callage`;
const TAS_PFD_SALES_TARGET = `${TAS}/pfd-reports`;
const OUTLET_TARGET = `${salesForceUrl}/outlet-target`;
const OUTLET_TARGET_SUBD = `${salesForceUrl}/outlet-target/subd`;
const FUNDAMENTAL_USER_TARGET = `${salesForceUrl}/user-target`;
const FUNDAMENTAL_USER_TARGET_DETAILS = `${salesForceUrl}/user-target/details`;
const OUTLET_TARGET_SUBD_DETAILS = `${OUTLET_TARGET_SUBD}`;
const USER_TARGET = `${salesForceUrl}/user-target`;
const USER_TARGET_DETAILS = `${USER_TARGET}/details`;

const TWO_FACTOR_AUTHENTICATION = '/two-factor-auth';
const STOCK_COUNT = `${INVENTORY_BASE}/stock-count`;
const STOCK_COUNT_CREATE = `${STOCK_COUNT}/create`;
const STOCK_COUNT_DETAIL = `${STOCK_COUNT}/details`;
const TRIP_WISE_COLLECTION = `${SALES_BASE}/${COLLECTION_BASE}/tripwise-collection`;

const TARGET_DISTRIBUTION = `${salesForceUrl}/target-distribution`;
const TARGET_DISTRIBUTION_CREATE = `${TARGET_DISTRIBUTION}/create`;
const TARGET_DISTRIBUTION_DETAILS = `${TARGET_DISTRIBUTION}/details`;

const SBD_TARGET = `${salesForceUrl}/sbd-target`;
const SBD_TARGET_CREATE = `${SBD_TARGET}/create`;
const SBD_TARGET_DETAILS = `${SBD_TARGET}/details`;
const LINKED_DEVICE = `${SECURITY}/linked-device`;
const LINKED_DEVICES_ADD = `${SECURITY}/add-device`;

const skuGroup = {
  id: 889,
  title: SKU_GROUP_TITLE,
  path: `/${SKU_GROUP}`,
  active: false,
};
const icoGroup = {
  id: 890,
  title: ICO_GROUP_TITLE,
  path: `/${ICO_GROUP}`,
  active: false,
};

export {
  ARC,
  BU,
  DSR,
  PSR,
  NSR,
  GRN,
  SKU,
  SRN,
  PRN,
  SBD,
  test,
  TOWN,
  login,
  SUB_D,
  ROUTE,
  CHANNEL,
  OUTLET,
  CATALOG,
  USER_ROLE,
  USER_ROLE_DETAILS,
  USER_ROLE_CREATE,
  TERRITORY,
  PROMOTION,
  GRN_CREATE,
  PRN_CREATE,
  MERCHANDISE,
  MERCHANDISE_DETAILS,
  MERCHANDISE_CREATE,
  SKU_CREATE,
  SRN_CREATE,
  GRN_DETAILS,
  SKU_DETAILS,
  SRN_DETAILS,
  PRN_DETAILS,
  SBD_DETAILS,
  FOCUSED_SKU,
  ROUTE_CREATE,
  SUB_D_CREATE,
  STOCK_LEDGER,
  USER_ACCOUNT,
  USER_CREATE,
  DESIGNATION,
  ORDER_INVOICE,
  SUB_D_DETAILS,
  ROUTE_DETAILS,
  OUTLET_CREATE,
  OUTLET_DETAILS,
  OPENING_BALANCE,
  CHANNEL_DETAILS,
  STOCK_ADJUSTMENT,
  LEAVE_MANAGEMENT,
  SUB_D_ASSORTMENT,
  ORDER_FULFILMENT,
  ORDER_FULFILMENT_CREATE,
  PROMOTION_DETAILS,
  TARGET_ACHIEVEMENT,
  TARGET_TOOLS,
  ROUTE_SEGMENTATION,
  FOCUSED_SKU_DETAILS,
  SUB_D_ASSORTMENT_DETAILS,
  TARGET_ACHIEVEMENT_DETAILS,
  ROUTE_SEGMENTATION_DETAILS,
  configuration,
  inventory,
  sales,
  details,
  DASHBOARD,
  CALL_ROSTER,
  SALES_FORCE_ROSTER,
  salesForce,
  reports,
  PRODUCTIVITY,
  USER_CLUSTER,
  DISTRIBUTION_COVERAGE,
  CHANNEL_ASSORTMENT,
  CHANNEL_ASSORTMENT_DETAILS,
  REPLENISHMENT,
  REPLENISHMENT_DETAILS,
  SUBD_STOCK_AVAILABILITY,
  SUBD_REPLENISHMENT_REPORT,
  STOCK_REPLACEMENT_REPORT,
  ASM_REPORT,
  STL_REPORT,
  DSE_REPORT,
  DSE_DETAIL,
  dashboard,
  CALLAGE,
  ERR_BASE,
  CATALOG_DETAIL_LIST,
  SKU_GROUP,
  ICO_GROUP,
  SKU_COVERAGE,
  BRAND_COVERAGE,
  TARGET_ACHIEVEMENT_REPORT,
  collection,
  RECEIPTS,
  CHEQUES,
  CASH,
  POST_DATED_CHEQUES,
  PFD_SALES_TARGET,
  PRODUCTIVE_COVERAGE,
  PRODUCTIVE_COVERAGE_DETAIL,
  CLASSIFICATION,
  OUTSTANDINGS,
  ORDER_SUMMARY_REPORT,
  SKU_DISTRIBUTION,
  SKU_DISTRIBUTION_DETAILS,
  BRAND_DISTRIBUTION,
  BRAND_DISTRIBUTION_DETAIL,
  PROFILE,
  userprofile,
  PUSH_MESSAGE,
  PUSH_MESSAGE_CREATE,
  PUSH_MESSAGE_DETAILS,
  WAREHOUSE_LEVELS,
  WAREHOUSE,
  WAREHOUSE_REPORT,
  WAREHOUSE_INVENTORY,
  SRN_PENDING,
  TARGET_COVERAGE,
  TARGET_COVERAGE_DETAILS,
  TARGET_BRAND_COVERAGE,
  TARGET_BRAND_COVERAGE_DETAILS,
  support,
  requests,
  callPlanBase,
  CATEGORY_CHANGE_REQUEST,
  ROUTE_TRANSFER,
  GEO_LOCATION,
  DE_BOARD_OUTLET,
  TIME_SPEND_ON_ROUTE,
  ATTENDANCE,
  MERCHANDISE_REPORT,
  LOCATION_HIERARCHY,
  LOCATION_HIERARCHY_DETAILS,
  SYSTEM_ALERT,
  SYSTEM_ALERT_CREATE,
  SYSTEM_ALERT_DETAILS,
  CAMPAIGNS,
  CAMPAIGNS_DETAILS,
  SUB_CAMPAIGN,
  GRN_REPORT,
  PRESENTATION,
  ICO,
  DISTRIBUTOR_SALES_TARGET,
  DISTRIBUTOR_SALES_TARGET_DETAILS,
  CALL_PLAN,
  CALL_PLAN_BASE,
  TAS_OUTLET,
  TAS_DASHBOARD,
  TAS_USERDETAIL_DASHBOARD,
  TAS_CALLAGE,
  TAS_PFD_SALES_TARGET,
  TAS_PRODUCTIVITY,
  TAS_REPORT,
  ORDER_ANALYSIS_REPORT,
  OUTLET_TARGET,
  OUTLET_TARGET_SUBD,
  FUNDAMENTAL_USER_TARGET,
  FUNDAMENTAL_USER_TARGET_DETAILS,
  DATA_MASK,
  OUTLET_TARGET_SUBD_DETAILS,
  USER_TARGET,
  USER_TARGET_DETAILS,
  RETURN,
  RETURN_CREATE,
  RETURN_PENDING,
  RETURN_RECONCILED,
  TWO_FACTOR_AUTHENTICATION,
  STOCK_COUNT,
  STOCK_COUNT_CREATE,
  STOCK_COUNT_DETAIL,
  TRIP_WISE_COLLECTION,
  TARGET_DISTRIBUTION,
  TARGET_DISTRIBUTION_CREATE,
  TARGET_DISTRIBUTION_DETAILS,
  SBD_TARGET,
  SBD_TARGET_CREATE,
  SBD_TARGET_DETAILS,
  skuGroup,
  icoGroup,
  PERFECT_CALL,
  LINKED_DEVICE,
  LINKED_DEVICES_ADD,
};
