import { Input } from '../../../../components';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { tableData } from '../../../../v4/components';

const labelMappings = {
  channel: 'channel',
  monthTarget: 'monthTarget',
  zone: 'zone',
  target: 'target',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.monthTarget,
    title: 'Month Target',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 3,
    label: labelMappings.zone,
    title: 'Zone',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.target,
    title: 'Target',
    classNames: 'text-right',
    show: true,
  },
];

const returnNewTableBody = (data, locationEnabled, handleInputChange, read) => {
  const table = {};
  table[labelMappings.channel] = <td key={`${data.id}-channel`}>{data.channel}</td>;
  table[labelMappings.monthTarget] = (
    <td key={`${data.id}-monthTarget`} className="text-right">
      {fixedFloatAndCommas(data?.targetContribution)}
    </td>
  );
  if (locationEnabled) {
    table[labelMappings.channel] = <td key={`${data.id}-channel`}>{data?.territory}</td>;
    table[labelMappings.monthTarget] = (
      <td key={`${data.id}-monthTarget`} className={`w-200 text-right`}>
        {read ? (
          fixedFloatAndCommas(data?.targetContribution)
        ) : (
          <Input
            value={Number(data?.targetContribution?.toFixed(4))}
            name="targetContribution"
            type="number"
            argument={{ min: 0 }}
            placeholder="0"
            enableValidation
            onChange={e => handleInputChange(e.target.value, data)}
            decimalWithPrecedingZero
          />
        )}
      </td>
    );
  }
  return table;
};

const NewTableBody = ({ data, locationEnabled, handleInputChange, read }) => {
  const tableBody = returnNewTableBody(data, locationEnabled, handleInputChange, read);
  return <tr key={data.id}>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</tr>;
};

const NewTableHeader = ({ locationEnabled }) => {
  const newHeader = locationEnabled
    ? labelConfig.filter(item => [labelMappings.zone, labelMappings.target]?.includes(item?.label))
    : labelConfig.filter(item => ![labelMappings.zone, labelMappings.target]?.includes(item?.label));
  return (
    <thead className="">
      <tr>{newHeader.map(label => (label.show ? tableData(label) : null))}</tr>
    </thead>
  );
};

export { NewTableHeader, NewTableBody };
