import { useState, useEffect, useMemo } from 'react';
import * as queryService from '../../../../base/query.service';
import { initialDialogState, achievementCriteriaTypeOptions } from '../config';
import { EVENT_OPERATION } from '../../../../../v4/constants/EventOperation';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { isError } from '../../../../common/HelperFunctions';
import { getPermissionForSkuGroup } from '../../../../base/permission';
import { MESSAGE_EVENT_OPERATION } from '../../../../../v4/constants/successMessage';
import { sequenceFormattedData, serverToClientDataMapper } from '../DataMapper/dataMapper';

const usePerfectCallList = props => {
  const {
    displayAlert,
    serverResponseWaiting,
    getPerfectCallListApi,
    createPerfectCalls,
    updatePerfectCalls,
    updatePerfectCallSequence,
    getBuData,
    deletePerfectCall,
  } = props;

  const [data, setData] = useState({ list: [], total: 0 });
  const [metricList, setMetricList] = useState([]);
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    filter: queryService.baseQueryParameters.filter,
    date: { ...queryService.baseQueryParameters.date },
    search: queryService.baseQueryParameters.search,
  });

  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [dialogData, setDialogData] = useState({
    ...initialDialogState,
  });

  const loadTableData = () => {
    getPerfectCallListApi(
      {
        filter: {
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: res => {
          if (isError(res)) onAPIRequestFailure(res.errors[0]);
          const response = res.data.perfectCalls;
          setData({
            list: response ? serverToClientDataMapper(response.rows) : [],
            total: response ? response.count : 0,
          });
        },
        handleError: err => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const setParameters = queryParams => setQueryParameters({ ...queryParams });

  const basePaginationService = new queryService.QueryClass(setParameters, () => queryParameters, loadTableData);

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
    setDialogData(initialDialogState);
  };

  const handleIconClick = (type, element = {}) => {
    setDialog({
      type,
      element,
    });
    if (type !== EVENT_OPERATION.CREATE) {
      setDialogData({
        id: element.id,
        metric: element.metric,
        achievementCriteriaType: element?.achievementCriteriaType,
        achievementCriteriaValue: element?.achievementCriteriaValue,
        active: element.active,
        sequence: element.sequence
      });
    }
  };

  const handleInputChange = e => {
    setDialogData({ ...dialogData, [e.target.name]: e.target.value });
  };

  const handleDialogSubmit = type => {
    if (type === EVENT_OPERATION.DELETE) {
      deletePerfectCall(
        {
          id: dialogData?.id,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${MESSAGE_EVENT_OPERATION.DELETE}`);
            loadTableData();
            resetDialog();
          },
          handleError: err => {
            onAPIRequestFailure(err);
          },
        },
      );

      return;
    }

    if (type === EVENT_OPERATION.UPDATE) {
      updatePerfectCalls(
        {
          id: dialogData.id,
          input: {
            alias: dialogData.metric?.id,
            title: dialogData.metric?.title,
            criteria_type: dialogData.achievementCriteriaType?.value,
            criteria_value: Number(dialogData.achievementCriteriaValue),
            sequence: dialogData?.sequence,
            active: dialogData.active,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${MESSAGE_EVENT_OPERATION.UPDATE}`);
            loadTableData();
            resetDialog()
          },
          handleError: err => {
            onAPIRequestFailure(err);
          },
        },
      );
    }

    if (type === EVENT_OPERATION.CREATE) {
      createPerfectCalls(
        {
          input: {
            alias: dialogData.metric?.id,
            title: dialogData.metric?.title,
            criteria_type: dialogData.achievementCriteriaType?.value,
            criteria_value: Number(dialogData.achievementCriteriaValue),
            sequence: data?.list?.length + 1,
            active: dialogData.active,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${MESSAGE_EVENT_OPERATION[type]}`);
            loadTableData();
            resetDialog();
          },
          handleError: err => {
            onAPIRequestFailure(err);
          },
        },
      );
    }
  };

  const getFilterStateFromStorage = () => {
    loadTableData();
  };

  const permission = getPermissionForSkuGroup();

  const handleDropDownChange = (eventData, eventKey) => {
    setDialogData({ ...dialogData, [eventKey]: eventData });
  };

  const onSequenceChange = data => {
    const formattedData = sequenceFormattedData(data);

    updatePerfectCallSequence(
      {
        input: formattedData,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `Sequence changed successfully`);
          loadTableData();
        },
        handleError: err => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const fetchBuData = () => {
    getBuData(
      {},
      {
        handleSuccess: res => {
          if (isError(res)) onAPIRequestFailure(res.errors[0]);
          const response =
            res?.data?.settings?.orderTakingSequence?.sequencing?.map(item => {
              return {
                id: item.alias,
                title: item.title,
              };
            }) || [];
          setMetricList(response);
        },
        handleError: err => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const unusedMetricList = useMemo(() => {
    return (
      metricList?.length > 0 &&
      metricList?.filter(item => !data?.list?.find(rowItem => rowItem.metric?.id === item?.id))
    );
  }, [metricList, data]);

  useEffect(() => {
    fetchBuData();
    getFilterStateFromStorage();
  }, []);

  useEffect(() => {
    setData({
      list: [],
      total: 0,
    });

    loadTableData();
  }, [queryParameters]);

  return {
    dialog,
    data,
    dialogData,
    serverResponseWaiting,
    permission,
    queryParameters,
    basePaginationService,
    deletePerfectCall,
    metricList,
    unusedMetricList,
    achievementCriteriaTypeOptions,
    handleDialogSubmit,
    resetDialog,
    handleInputChange,
    handleIconClick,
    handleDropDownChange,
    onSequenceChange,
  };
};

export default usePerfectCallList;
