import React from 'react';
import * as dateUtil from '../../../utils/date';
import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import {
  INSTOCK, OUT_OF_STOCK, STOCK_INSUFFICIENT
} from '../../../data/enums/GeneralConstants';
import { sales as salesRoute } from '../../../data/enums/Route';

const breadCrumb = [salesRoute];

const SKU_STATUS = {
  [INSTOCK]: 'IN STOCK',
  [OUT_OF_STOCK]: 'OUT OF STOCK',
  [STOCK_INSUFFICIENT]: 'INSUFFICIENT',
};
const skuStatusLabel = 'inStock';
const ORDER_TABS = {
  RECEIVED: 'received',
  INVOICED: 'invoiced',
  // REPLACED: 'replaced',
  DISPATCHED: 'dispatched',
  DELIVERED: 'delivered',
};
const SERVER_ORDER_STATUS = {
  [ORDER_TABS.RECEIVED]: 'RECEIVED',
  [ORDER_TABS.INVOICED]: 'INVOICED',
  // [ORDER_TABS.REPLACED]: 'REPLACED',
  [ORDER_TABS.DISPATCHED]: 'DISPATCHED',
  [ORDER_TABS.DELIVERED]: 'DELIVERED',
  cancelled: 'CANCELLED',
};

const tabMapper = {
  0: ORDER_TABS.RECEIVED,
  1: ORDER_TABS.INVOICED,
  // 2: ORDER_TABS.REPLACED,
  2: ORDER_TABS.DISPATCHED,
  3: ORDER_TABS.DELIVERED,
};

const ORDER_TYPE = {
  REPLACEMENT: 'REPLACEMENT',
  NORMAL: 'NORMAL',
};

const imageUrlPrefix = 'https://storage.googleapis.com/rosia-bu-assets/';

const storageKeys = {
  FILTER: 'FILTER',
};

const defaultDateConfig = {
  start: dateUtil.getCurrentDay(),
  end: dateUtil.getCurrentDay(),
};

const MTD = {
  start: dateUtil.getStartOfCurrentMonth(),
  end: dateUtil.getCurrentDay(),
};
const getCreatePermission = () => {
  const buSettingGet = localStorage.getItem('rosia-bu-settings');
  const buSetting = JSON.parse(buSettingGet);
  return buSetting?.web_order ?? false;
};
const filter = getFilterConfig([FILTER.SUB_D, FILTER.CHANNEL, FILTER.CATEGORY, FILTER.ROUTE, FILTER.BRANCH_HEAD]);

const skuStockValidation = (orders) => (orders.find((item) => item[skuStatusLabel] !== INSTOCK) ? OUT_OF_STOCK : INSTOCK);

const OrderFulFilmentContext = React.createContext({});

const orderBasedListReferencer = (orderType) => (orderType === ORDER_TYPE.REPLACEMENT ? 'refId' : 'id');

export {
  SKU_STATUS,
  ORDER_TABS,
  tabMapper,
  filter,
  skuStatusLabel,
  OrderFulFilmentContext,
  breadCrumb,
  SERVER_ORDER_STATUS,
  skuStockValidation,
  storageKeys,
  defaultDateConfig,
  imageUrlPrefix,
  ORDER_TYPE,
  orderBasedListReferencer,
  MTD,
  getCreatePermission,
};
