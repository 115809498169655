import { EVENT_OPERATION } from './EventOperation';

const FORM_CONFIG = {
  MAPPER: 'mapper',
  REFS_OBJ: 'refsObj',
  TITLE: 'title',
  VALIDATION_FIELD: 'validationField',
  VALIDATION_REQUIRED: 'validationRequired',
};

const CONFIRMATION_CONFIG = {
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id || '',
    }),
    [FORM_CONFIG.TITLE]: 'Confirm',
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      ids: element || '',
    }),
    [FORM_CONFIG.TITLE]: 'Confirm',
  },
  [EVENT_OPERATION.UPDATE_STATUS]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id || '',
    }),
    [FORM_CONFIG.TITLE]: 'Confirm',
  },
  [EVENT_OPERATION.CHANGE_STATUS]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id || '',
    }),
    [FORM_CONFIG.TITLE]: '',
  },
};

const WIDGETS = {
  SORT: 'SORT',
  FILTER: 'FILTER',
  SEARCH: 'SEARCH',
  UPLOAD: 'UPLOAD',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  VIEW: 'VIEW',
  MANUAL: 'MANUAL',
  PRINT: 'PRINT',
};

const DOMAIN = {
  DBS_SALES: 'DBS_SALES',
  DBS_SALES_RETURN: 'DBS_SALES_RETURN',
  DBS_PURCHASE_RETURN: 'DBS_PURCHASE_RETURN',
  DBS_PURCHASE: 'DBS_PURCHASE',
  DBS_GRN: 'DBS_GRN',
  DBS_ABBREVIATED_SALES:'DBS_ABBREVIATED_SALES',
  ABBREVIATED_INVOICE: 'ABBREVIATED_INVOICE',
  BRAND_COVERAGE: 'BRAND_COVERAGE',
  CASH: 'CASH',
  CHANNEL_ASSORTMENT: 'CHANNEL_ASSORTMENT',
  CHANNEL_CATEGORY: 'CHANNEL_CATEGORY',
  FOCUSED_SKU: 'FOCUSED_SKU',
  GRN: 'GRN',
  ICO: 'ICO',
  OPENING_BALANCE: 'OPENING_BALANCE',
  PROMOTION: 'PROMOTION',
  PURCHASE_RETURN: 'PURCHASE_RETURN',
  REPLENISHMENT: 'REPLENISHMENT',
  RETAIL_OUTLET: 'RETAIL_OUTLET',
  ROUTE: 'ROUTE',
  SALES: 'SALES',
  SALES_RETURN: 'SALES_RETURN',
  SBD: 'SBD',
  SKU: 'SKU',
  SKU_COVERAGE: 'SKU_COVERAGE',
  SRN: 'SRN',
  RETURN: 'RETURN',
  PRN: 'PRN',
  STOCK_ADJUSTMENT: 'STOCK_ADJUSTMENT',
  STOCK_AVAILABILITY: 'STOCK_AVAILABILITY',
  STOCK_LEDGER: 'STOCK_LEDGER',
  STOCK_REPLACEMENT: 'STOCK_REPLACEMENT',
  STOCK_REPLENISHMENT: 'STOCK_REPLENISHMENT',
  STOCK_REPLENISHMENT_ALL: 'STOCK_REPLENISHMENT_ALL',
  SUB_D: 'SUB_D',
  TAS_CALL_PLAN: 'TAS_CALL_PLAN',
  TERRITORY: 'TERRITORY',
  TOWN: 'TOWN',
  USER_ACCOUNT: 'USER_ACCOUNT',
  USER_ROLE: 'USER_ROLE',
  WARE_HOUSE: 'WARE_HOUSE',
  WAREHOUSE_REPORT: 'WAREHOUSE_REPORT',
  WAREHOUSE_INVENTORY: 'WAREHOUSE_INVENTORY',
  OUTSTANDING: 'OUTSTANDING',
  RECEIPT: 'RECEIPT',
  DISTRIBUTOR_SALES_TARGET: 'DISTRIBUTOR_SALES_TARGET',
  POSMs: 'POSMs',
  LEAVE_MANAGEMENT: 'LEAVE_MANAGEMENT',
  ATTENDANCE: 'ATTENDANCE',
  PUSH_MESSAGE: 'PUSH_MESSAGE',
  PRODUCTIVITY: 'PRODUCTIVITY',
  PSR: 'PSR',
  NSR: 'NSR',
  USER_TARGET: 'USER_TARGET',
  TAS_REPORT: 'TAS_REPORT',
  OSR: 'OSR',
  USER_CLUSTER: 'USER_CLUSTER',
  TIME_ON_ROUTE: 'TIME_ON_ROUTE',
  MERCHANDISE_REPORT: 'MERCHANDISE_REPORT',
  GRN_REPORT: 'GRN_REPORT',
  CATEGORY_CHANGE: 'CATEGORY_CHANGE',
  DEBOARD_OUTLET: 'DEBOARD_OUTLET',
  GEO_LOCATION: 'GEO_LOCATION',
  ROUTE_TRANSFER: 'ROUTE_TRANSFER',
  CALL_PLAN: 'CALL_PLAN',
  SUBD_REPLENISHMENT: 'SUBD_REPLENISHMENT',
  SUBD_STOCK: 'SUBD_STOCK',
  STOCK_REPLACEMENT_REPORT: 'STOCK_REPLACEMENT_REPORT',
  CHEQUE: 'CHEQUE',
  POST_DATED: 'POST_DATED',
  DST: 'DISTRIBUTOR_SALES_TARGET',
  OFT: 'OUTLET_FUNDAMENTAL_TARGET',
  PRESENTATION: 'PRESENTATION',
  DSE_REPORT: 'DSE_REPORT',
  STOCK_COUNT: 'STOCK_COUNT',
  TRIP_WISE_COLLECTION:'TRIP_WISE_COLLECTION',
  SKU_GROUP: 'SKU_GROUP',
  ICO_GROUP: 'ICO_GROUP',
};

const BU_LABEL = {
  BRIT: 'brit',
  PG: 'pg',
  MARS: 'mars',
  KELLOGGS: 'kelloggs',
};

const BU_DSE_LABEL = {
  [BU_LABEL.BRIT]: 'AWSM',
  [BU_LABEL.PG]: 'DSE',
  [BU_LABEL.MARS]: 'DSE',
  [BU_LABEL.KELLOGGS]: 'DSE',
};

export {
  DOMAIN, FORM_CONFIG, CONFIRMATION_CONFIG, WIDGETS, BU_DSE_LABEL, BU_LABEL
};
