import { CALLAGE, SALES } from '../../data/enums/Dashboard';
import {
  ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES, USER_LABEL_ROLE_MAPPER
} from '../../data/enums/UserRole';
import { USER_ROLE } from '../../data/enums';
import { getUserRole } from '../../data/dao';

const callageColorSet = {
  [CALLAGE.successful]: '#33AC2E',
  [CALLAGE.unsuccessful]: '#FF5F5F',
  [CALLAGE.remaining]: '#FFB46E',
  [CALLAGE.offroute]: '#5FC8FF',
};

const salesColorSet = {
  [SALES.achievement]: '#33AC2E',
  [SALES.target]: '#f4f6fc',
};

const WORKING_STATUS = {
  active: 'active',
  inactive: 'inactive',
  leave: 'on leave',
};

const isAdminUser = (roleId) => !(!ADMIN_ROLE_GROUP.includes(roleId) && !DISTRIBUTOR_ASSOCIATED_ROLES.includes(roleId));

const isTasUser = (roleId) => {
  return roleId===USER_ROLE.VISIBILITY_TEAM_LEADER||roleId===USER_ROLE.TAS;
}

const getDashboardVisibleComponent = (admin = true, subd = false) => ({
  userDetail: subd || admin,
  monthDetailView: subd || admin,
  userStatus: admin,
  callage: admin,
  sales: admin,
  inventoryDetail: subd,
  deliveryDetail: subd,
  routeCoverageView: subd,
  dseDetail: subd,
});

const getUserWiseView = (roleId) => {
  let admin = false;
  admin = isAdminUser(roleId);
  return roleId===USER_ROLE.AUDITOR?getDashboardVisibleComponent(false, false):getDashboardVisibleComponent(admin, !admin);
};

const defaultCallageData = {
  [CALLAGE.successful]: 0,
  [CALLAGE.unsuccessful]: 0,
  [CALLAGE.remaining]: 0,
  [CALLAGE.offroute]: 0,
};

const defaultSalesData = {
  target: 0,
  achievement: 0,
};

const userStatusMapper = (data = {}) => ({
  active: data.active || 0,
  inactive: data.inactive || 0,
  leave: data.leave || 0,
});

const userStatusRule = {
  dsmOnwards: [USER_ROLE.DSM, USER_ROLE.ASM, USER_ROLE.STL, USER_ROLE.DSE],
  asmOnwards: [USER_ROLE.ASM, USER_ROLE.STL, USER_ROLE.DSE],
  stlOnwards: [USER_ROLE.STL, USER_ROLE.DSE],
  tsiOnwards: [USER_ROLE.DSE],
};

const roleBasedUserStatusListMapper = {
  [USER_ROLE.NSM]: userStatusRule.asmOnwards,
  [USER_ROLE.DSM]: userStatusRule.asmOnwards,
  [USER_ROLE.ASM]: userStatusRule.asmOnwards,
  [USER_ROLE.SUPER_ADMIN]: userStatusRule.asmOnwards,
  [USER_ROLE.ADMIN]: userStatusRule.asmOnwards,
  [USER_ROLE.LEADERSHIP]: userStatusRule.asmOnwards,
  [USER_ROLE.BRANCH_HEAD]: userStatusRule.asmOnwards,
  [USER_ROLE.MIS_ADMIN]: userStatusRule.asmOnwards,
  [USER_ROLE.MIS_ASSOCIATE]: userStatusRule.asmOnwards,
  [USER_ROLE.STL]: userStatusRule.stlOnwards,
  [USER_ROLE.TSI]: userStatusRule.tsiOnwards,
};

const roleBasedUserStatus = (data = {}, roleId) => {
  const userStatus = {};
  const filteredUsers = Object.keys(USER_LABEL_ROLE_MAPPER)?.reduce((acc, role, index) => {
    const id = parseInt(role.replace('_', '') || 0, 10);
    if (roleBasedUserStatusListMapper[roleId] && roleBasedUserStatusListMapper[roleId].includes(id)) {
      acc.push(USER_LABEL_ROLE_MAPPER[role]);
    }
    return acc;
  }, []);
  filteredUsers.forEach((user) => (userStatus[user] = userStatusMapper(data[user] || {})));

  return userStatus || {};
};

const dataMapper = (data = {}, roleId) => ({
  sales: data.sales || [],
  callage: data.callage || [],
  userStatus: roleBasedUserStatus(data, roleId),
  user: { title: data.user ? `${data.user.firstName} ${data.user.lastName}` || '' : '' },
  monthlyDetail: data.monthlyDetail || {},
  dseDetails: data.dseDetail
    ? data.dseDetail
    : {
      rows: [
        {
          id: '',
          name: '',
          outlet: [
            {
              id: '',
              title: '',
              location: {
                latitude: '0',
                longitude: '0',
              },
            },
          ],
        },
      ],
      total: {
        scheduled: 0,
        successful: 0,
        unsuccessful: 0,
        remaining: 0,
        productivity: 0,
        target: 0,
        received: 0,
        goldenpoint: 0,
      },
    },
  inventoryDetails: {},
  deliveryDetails: {},
  displayGoldenPoint: false,
});

const getDataItem = (value = null, label = '', data = {}) => ({
  label: label || '',
  value: value === 0 ? value : value || data[label] || '',
});

const randomColor = () => {
  const symbols = 'ABCDEF0123456789';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += symbols[Math.floor(Math.random() * 16)];
  }
  return color;
};

const callageDataFormatter = (list, scheduled, percent) => {
  let scheduledData = { ...scheduled };
  let percentData = { ...percent };
  const formattedList = list?.reduce((acc, item) => {
    if (item.label === CALLAGE.scheduled) {
      scheduledData = { ...item };
    } else if (item.label === CALLAGE.productivity) {
      percentData = { ...item };
    } else if (item.label === 'offroute') {
      acc.push(getDataItem(item.value, 'off route'));
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  return { formattedList, scheduledData, percentData };
};

const MODULES = {
  TARGET_ACHIEVEMENT: 'TARGET_ACHIEVEMENT',
  CALLAGE: 'CALLAGE',
  SALES: 'SALES',
  PFD_SALES_TARGET: 'target',
  PRODUCTIVE_COVERAGE: 'productive_coverage',
};

export {
  callageColorSet,
  getDataItem,
  salesColorSet,
  WORKING_STATUS,
  dataMapper,
  getUserWiseView,
  isAdminUser,
  userStatusMapper,
  randomColor,
  defaultCallageData,
  defaultSalesData,
  callageDataFormatter,
  roleBasedUserStatus,
  MODULES,
  isTasUser
};
