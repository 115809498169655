import React from 'react';
import PropTypes from 'prop-types';
import withAlert from '../../../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../../../common';
import PerfectCallTableList from '../components/PerfectCallTableList/PerfectCallTableList';
import { formConfig, breadCrumbConfig } from '../config';
import { PanelStyled } from '../../../../common/configuration';
import PageHeader from '../../../../base/PageHeader';
import { EVENT_OPERATION } from '../../../../../v4/constants/EventOperation';
import PerfectCallCreateUpdateDialog from '../components/PerfectCallCreateUpdateDialog/PerfectCallCreateUpdateDialog';
import confirmationGenerator from '../../../../../v4/components/DialogConfirmation/DialogConfirmation';
import usePerfectCallList from '../Controller/usePerfectCallList.controller';
import { PerfectCallListStyled } from './PerfectCallListStyled';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const PerfectCallList = props => {
  const {
    dialog,
    data,
    dialogData,
    serverResponseWaiting,
    permission,
    queryParameters,
    basePaginationService,
    metricList,
    unusedMetricList,
    achievementCriteriaTypeOptions,
    handleDialogSubmit,
    resetDialog,
    handleInputChange,
    handleIconClick,
    handleDropDownChange,
    onSequenceChange,
  } = usePerfectCallList(props);

  return (
    <PerfectCallListStyled>
      {dialog.type && (
        <DialogFormWrapper
          formConfig={formConfig[dialog.type]}
          formTitle={dialogData?.metric?.title}
          dialogElement={dialog.element}
          onDialogSubmit={handleDialogSubmit}
          onDialogCancel={resetDialog}
          type={dialog.type}
          disableDialogClose
          renderDialog={({ refsObj, enableErrorDisplay }) => (
            <>
              {dialog.type === EVENT_OPERATION.DELETE &&
                confirmationGenerator(EVENT_OPERATION.DELETE, null, data.title)}
              {(dialog.type === EVENT_OPERATION.CREATE ||
                dialog.type === EVENT_OPERATION.UPDATE) && (
                <PerfectCallCreateUpdateDialog
                  dialog={dialog}
                  data={dialogData}
                  refsObj={refsObj}
                  loading={serverResponseWaiting}
                  handleInputChange={handleInputChange}
                  enableErrorDisplay={enableErrorDisplay}
                  metricOptions={metricList}
                  unusedMetricOptions={unusedMetricList}
                  achievementCriteriaTypeOptions={achievementCriteriaTypeOptions}
                  handleDropDownChange={handleDropDownChange}
                />
              )}
            </>
          )}
        />
      )}
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title: 'Perfect Call',
              create: permission.create,
              filter: false,
              date: false,
              search: true,
            }}
            queryParameters={queryParameters}
            handleCreateClick={() => handleIconClick(EVENT_OPERATION.CREATE, {})}
            handleSearchChange={basePaginationService.handleSearchInputChange}
          />
        </PanelStyled>
      </div>
      <div className="section-content table-present">
        <PerfectCallTableList
          data={data}
          loading={serverResponseWaiting}
          handleIconClick={handleIconClick}
          pagination={queryParameters.pagination}
          onPageChange={basePaginationService.onPageSelect}
          permission={permission}
          onBulkDelete={() => {}}
          onSequenceChange={onSequenceChange}
        />
      </div>
    </PerfectCallListStyled>
  );
};

PerfectCallList.propTypes = propTypes;

PerfectCallList.defaultProps = defaultProps;

export default withAlert()(PerfectCallList);
